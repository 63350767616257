import React, { useState } from 'react';
import { Button, Col, Row, Select } from 'antd';
import { SearchBar } from '../../../components/SearchBar';

import styles from '../Identities.module.css';
import { IdentityPurpose, IdentityType } from '../../../services/identity';
import { PlusOutlined } from '@ant-design/icons';
import { AddIdentityModal } from './AddIdentityModal';

const { Option } = Select;

export const Header = ({ filters, searchValue, onFilter, onSearch, onAdd }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleOpenModal = () => setModalVisible(true);
  const handleCloseModal = () => setModalVisible(false);

  return (
    <>
      <Row gutter={32} className={styles.paddingBetweenRows}>
        <Col span={6}>
          <Select
            placeholder={'Select type'}
            style={{ width: '100%' }}
            value={filters.type}
            onChange={(value) => onFilter({ ...filters, type: value })}
            allowClear
          >
            <Option value={IdentityType.Fixed}>Fixed</Option>
            <Option value={IdentityType.Replacement}>Replacement</Option>
          </Select>
        </Col>
        <Col span={6}>
          <Select
            placeholder={'Select purpose'}
            style={{ width: '100%' }}
            value={filters.purpose}
            onChange={(value) => onFilter({ ...filters, purpose: value })}
            allowClear
          >
            <Option value={IdentityPurpose.WebPush}>WebPush</Option>
            <Option value={IdentityPurpose.Custom}>Custom</Option>
            <Option value={IdentityPurpose.AzureWebPush}>Azure WebPush</Option>
          </Select>
        </Col>
        <Col span={6}>
          <SearchBar value={searchValue} onChange={(e) => onSearch(e.target.value)} />
        </Col>
        <Col span={6}>
          <Button type="primary" icon={<PlusOutlined />} onClick={handleOpenModal}>
            Add New Identity
          </Button>
        </Col>
      </Row>

      <AddIdentityModal
        modalVisible={modalVisible}
        onCancel={handleCloseModal}
        onAdd={() => {
          onAdd();
          handleCloseModal();
        }}
      />
    </>
  );
};
