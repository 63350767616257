import { BarChartOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Radio, Row, Select, Spin, Tooltip } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { usePromise } from 'react-use';

import { renderCollapseRows } from '../../../components/ColumnsGroup';
import { FilterSwitch } from '../../../components/FilterSwitch';
import { VirtualizedSelect } from '../../../components/VirtualizedSelect';
import { accountsService } from '../../../services/accounts';
import { browsersService } from '../../../services/browsers';
import { STATS_CATEGORIES, companiesService } from '../../../services/companies';
import { countriesService } from '../../../services/countries';
import { deviceTypesService } from '../../../services/device-types';
import { identityService } from '../../../services/identity';
import { landingPagesService } from '../../../services/landingPages';
import { mediaCampaignsService } from '../../../services/media-campaigns';
import { osService } from '../../../services/os';
import { productsService } from '../../../services/products';
import { CampaignType, FilterName, SourcesSearchType } from '../../../services/statistic';
import { PublisherModules } from '../../../utils/enums';
import { firstLetterUpperCase } from '../../../utils/functions';
import {
  generateOptionsFromObject,
  generateSelectOptionsWithIdValues,
  generateOptionsFromObjectKeyValue,
  filterOptionsByLabelAndValue
} from '../../../utils/options';
import { showApiErrors } from '../../../utils/showApiErrors';
import styles from '../ChannelStats.module.css';
import { IncludeExcludeSelect } from './IncludeExcludeSelect';
import { PresetColumnConfig } from './PresetColumnConfig';
import { companyTagsService } from '../../../services/company-tags';
import { ChannelColumnGroups, getSelectedColumns } from '../utils/columns';

const { RangePicker } = DatePicker;

const ruleType = [
  { value: 1, label: 'Regular' },
  { value: 2, label: 'DDL' },
  { value: 3, label: 'External' }
];

const defaultActivePanelKeys = [
  ChannelColumnGroups.Dimensions,
  ChannelColumnGroups.Metrics,
  ChannelColumnGroups.CampaignInfo,
  ChannelColumnGroups.Pixels,
  ChannelColumnGroups.FiredPixels,
  ChannelColumnGroups.PixelsEarned,
  ChannelColumnGroups.Product,
  ChannelColumnGroups.PartnerInfo
];

const dateRangePresets = [
  {
    text: 'Today',
    value: 'today'
  },
  {
    text: 'Yesterday',
    value: 'yesterday'
  },
  {
    text: 'Last 2 Days',
    value: 'last_2_days'
  },
  {
    text: 'Last 3 Days',
    value: 'last_3_days'
  },
  {
    text: 'Last 7 Days',
    value: 'last_7_days'
  },
  {
    text: 'Last 30 Days',
    value: 'last_30_days'
  },
  {
    text: 'This Month',
    value: 'this_month'
  },
  {
    text: 'Last Month',
    value: 'last_month'
  }
];

const getFiltersWithoutDateRange = (filters) => {
  const { date_range, ...otherFilters } = filters;
  return otherFilters;
};

export const Filters = ({ currentPresetData, onPresetDataChange, onSubmit, onExport, loadingExportButton }) => {
  const [countries, setCountries] = useState([]);
  const [countriesLoading, setCountriesLoading] = useState(false);

  const [browsers, setBrowsers] = useState([]);
  const [browsersLoading, setBrowsersLoading] = useState(false);

  const [deviceTypes, setDeviceTypes] = useState([]);
  const [deviceTypesLoading, setDeviceTypesLoading] = useState(false);

  const [identities, setIdentities] = useState([]);
  const [identitiesLoading, setIdentitiesLoading] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [companiesLoading, setCompaniesLoading] = useState(false);

  const [productCompany, setProductCompany] = useState([]);
  const [productCompanyLoading, setProductCompanyLoading] = useState(false);

  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);

  const [channels, setChannels] = useState([]);
  const [channelsLoading, setChannelsLoading] = useState(false);

  const [dateRange, setDateRange] = useState({ from: moment(), to: moment() });
  const [isRangePickerOpen, setIsRangePickerOpen] = useState(false);
  const [displayFilters, setDisplayFilters] = useState(true);

  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);

  const [lps, setLps] = useState([]);
  const [lpsLoading, setLpsLoading] = useState(false);

  const [os, setOS] = useState([]);
  const [osLoading, setOSLoading] = useState(false);

  const [statuses, setStatuses] = useState([]);
  const [statusesLoading, setStatusesLoading] = useState(false);

  const [tags, setTags] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(false);

  const [checkedColumns, setCheckedColumns] = useState({});

  const [showSourceFilters, setShowSourceFilters] = useState(false);

  const [visibleColumnConfigModal, setVisibleColumnConfigModal] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);

  const [initialPresetLoad, setInitialPresetLoad] = useState(true);

  const dateRangePresetDates = {
    today: [moment(), moment()],
    yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    last_2_days: [moment().subtract(1, 'days'), moment()],
    last_3_days: [moment().subtract(2, 'days'), moment()],
    last_7_days: [moment().subtract(6, 'days'), moment()],
    last_30_days: [moment().subtract(30, 'days'), moment()],
    this_month: [moment().startOf('month'), moment().endOf('month')],
    last_month: [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')]
  };

  const dateRangePresetDatesRef = useRef(dateRangePresetDates);

  useEffect(() => {
    dateRangePresetDatesRef.current = dateRangePresetDates;
  });

  const mounted = usePromise();

  const filters = currentPresetData?.data?.filters || {
    [FilterName.Excluded]: [],
    [FilterName.SourcesSearchType]: SourcesSearchType.Exact
  };

  const containsSearchTypeImageName =
    filters[FilterName.SourcesSearchType] === SourcesSearchType.Contains ? 'cube_transp_blue' : 'cube_transp_grey';
  const exactSearchTypeImageName = filters[FilterName.SourcesSearchType] === SourcesSearchType.Exact ? 'cube_blue' : 'cube_grey';

  const handleFiltersChange = useCallback(
    (filters) => {
      onPresetDataChange((oldValues) => ({ ...oldValues, data: { ...oldValues.data, filters } }));
    },
    [onPresetDataChange]
  );

  const handleCheckedColumnsChange = useCallback(
    (newCheckedColumns) => {
      onPresetDataChange((oldValues) => {
        const newColumns =
          oldValues?.data?.columns?.map((column) => {
            if (!!column.selected !== !!newCheckedColumns[column.value]) {
              return { ...column, selected: !!newCheckedColumns[column.value] };
            }
            return column;
          }) || [];
        return { ...oldValues, data: { ...oldValues.data, columns: newColumns } };
      });
    },
    [onPresetDataChange]
  );

  const handleExcludedChange = (isExcluded, filterName) => {
    const excludedFiltersClone = [...filters[FilterName.Excluded]];
    const filterNameArrayIndex = excludedFiltersClone.indexOf(filterName);

    if (isExcluded && filterNameArrayIndex < 0) {
      excludedFiltersClone.push(filterName);
    } else if (!isExcluded && filterNameArrayIndex > -1) {
      excludedFiltersClone.splice(filterNameArrayIndex, 1);
    }

    handleFiltersChange({ ...filters, excluded: excludedFiltersClone });
  };

  const handlePresetChange = useCallback(
    (presetData) => {
      if (presetData) {
        const {
          data: { filters: presetFilters }
        } = presetData;
        const { date_range: dateRangePreset } = presetFilters;
        if (dateRangePreset) {
          setDateRange({
            from: dateRangePresetDatesRef.current[dateRangePreset][0],
            to: dateRangePresetDatesRef.current[dateRangePreset][1]
          });
        }
        onPresetDataChange({
          ...presetData,
          data: { ...presetData.data, filters: { ...presetFilters, excluded: presetFilters.excluded ?? [] } }
        });
      }
    },
    [onPresetDataChange]
  );

  const handleUpdate = (urlFilters, urlCheckedColumns) => {
    onSubmit({
      filters: urlFilters || getFiltersWithoutDateRange(filters),
      dateRange: dateRange,
      checkedColumns: urlCheckedColumns || checkedColumns
    });
  };

  useEffect(() => {
    const getChannels = async () => {
      try {
        setChannelsLoading(true);
        const channels = await mounted(mediaCampaignsService.getCampaigns());
        setChannels(channels);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setChannelsLoading(false);
      }
    };

    const getLps = async () => {
      try {
        setLpsLoading(true);
        const lps = await mounted(landingPagesService.getAll());
        setLps(lps);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLpsLoading(false);
      }
    };

    const getUsers = async () => {
      try {
        setUsersLoading(true);
        const users = await mounted(accountsService.getAll());
        setUsers(users);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setUsersLoading(false);
      }
    };

    const getProducts = async () => {
      try {
        setProductsLoading(true);
        const products = await mounted(productsService.getAll());
        setProducts(products);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setProductsLoading(false);
      }
    };

    const getCountries = async () => {
      try {
        setCountriesLoading(true);
        const countries = await mounted(countriesService.getAll({ fields: 'id,name,code2,img' }));
        setCountries(countries);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setCountriesLoading(false);
      }
    };

    const getBrowsers = async () => {
      try {
        setBrowsersLoading(true);
        const browsers = await mounted(browsersService.getAll());
        setBrowsers(browsers);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setBrowsersLoading(false);
      }
    };

    const getDeviceTypes = async () => {
      try {
        setDeviceTypesLoading(true);
        const deviceTypes = await mounted(deviceTypesService.getAll({ fields: 'name,regex' }));
        setDeviceTypes(deviceTypes);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setDeviceTypesLoading(false);
      }
    };

    const getIdentities = async () => {
      try {
        setIdentitiesLoading(true);
        const identities = await mounted(identityService.getAll());
        setIdentities(identities);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setIdentitiesLoading(false);
      }
    };

    const getCompanies = async () => {
      try {
        setCompaniesLoading(true);
        const companies = await mounted(companiesService.getTrafficCompanies());
        setCompanies(companies);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setCompaniesLoading(false);
      }
    };

    const getProductCompany = async () => {
      try {
        setProductCompanyLoading(true);
        const productCompany = await mounted(companiesService.getProductCompanies());
        setProductCompany(productCompany);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setProductCompanyLoading(false);
      }
    };

    const getOs = async () => {
      try {
        setOSLoading(true);
        let osFilters = await mounted(osService.getFilters());
        setOS(osFilters);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setOSLoading(false);
      }
    };

    const getStatuses = async () => {
      try {
        setStatusesLoading(true);
        const data = await mounted(mediaCampaignsService.getCampaignStatuses());
        setStatuses(data);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setStatusesLoading(false);
      }
    };

    const getTags = async () => {
      try {
        setTagsLoading(true);
        const data = await mounted(companyTagsService.getAll());
        setTags(data);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setTagsLoading(false);
      }
    };

    getCompanies();
    getChannels();
    getProductCompany();
    getBrowsers();
    getDeviceTypes();
    getIdentities();
    getProducts();
    getCountries();
    getUsers();
    getLps();
    getOs();
    getStatuses();
    getTags();
  }, [mounted]);

  useEffect(() => {
    const selectedColumns = getSelectedColumns(currentPresetData?.data?.columns || []);
    setCheckedColumns(selectedColumns);
  }, [currentPresetData]);

  const updatePresetFromUrlParams = () => {
    const search = new URLSearchParams(window.location.search);
    const channelString = search.get('channel');
    const urlColumns = search.get('columns');
    const lpsString = search.get('lp_id');
    const dateFrom = search.get('date_from');
    const dateTo = search.get('date_to');
    const advCompanyId = search.get('adv_company_id');
    const excluded = search.get('excluded');

    if (dateFrom && dateTo) {
      setDateRange({ from: moment(dateFrom), to: moment(dateTo) });
    }

    if (channelString || lpsString || advCompanyId || excluded) {
      const urlFilters = {
        [FilterName.Channels]: channelString ? channelString.split(',') : [],
        [FilterName.LPs]: lpsString ? lpsString.split(',') : [],
        [FilterName.TrafficCompanies]: advCompanyId ? advCompanyId.split(',') : [],
        [FilterName.Excluded]: excluded ? excluded.split(',') : [],
        [FilterName.SourcesSearchType]: SourcesSearchType.Exact
      };

      handleFiltersChange(urlFilters);
    }
    let urlCheckedColumns = null;
    if (urlColumns) {
      urlCheckedColumns = {};
      urlColumns &&
        urlColumns.split(',').forEach((el) => {
          urlCheckedColumns[el] = true;
        });
      handleCheckedColumnsChange(urlCheckedColumns);
    }
  };

  const updatePresetFromUrlParamsRef = useRef(updatePresetFromUrlParams);

  useEffect(() => {
    updatePresetFromUrlParamsRef.current = updatePresetFromUrlParams;
  });

  useEffect(() => {
    if (initialPresetLoad && currentPresetData) {
      setInitialPresetLoad(false);
      updatePresetFromUrlParamsRef.current();
    }
  }, [currentPresetData, initialPresetLoad]);

  return (
    <div className={styles.spanFontWeight500}>
      <Row style={{ justifyContent: 'space-between' }}>
        <Col>
          <Row gutter={32}>
            <Col>
              <Button type="primary" ghost onClick={() => setDisplayFilters(!displayFilters)}>
                Filters
              </Button>
            </Col>

            <Col>
              <PresetColumnConfig
                presetData={currentPresetData}
                wrapperHeight="95vh"
                popoverPlacement="right"
                showUpdateButton={true}
                onUpdate={handleUpdate}
                renderColumnRows={() =>
                  renderCollapseRows({
                    panelHeaderTitles: ChannelColumnGroups,
                    defaultActivePanelKeys,
                    allColumns: currentPresetData?.data?.columns || [],
                    checkedColumns,
                    onChange: handleCheckedColumnsChange
                  })
                }
                visibleModal={visibleColumnConfigModal}
                onCancel={() => setVisibleColumnConfigModal(false)}
                onPresetChange={handlePresetChange}
                onPresetLoading={setFiltersLoading}
                onColumnsDataChange={onPresetDataChange}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={32}>
            <Col>
              <Button className="export-btn" type="primary" ghost>
                <Link
                  to={`/stubs/channel_chart/?channel=${
                    filters && filters[FilterName.Channels] ? filters[FilterName.Channels] : ''
                  }&products=${filters && filters[FilterName.Products] ? filters[FilterName.Products].join() : ''}&browser=${
                    filters && filters[FilterName.Browsers] ? filters[FilterName.Browsers].join() : ''
                  }&os=${filters && filters[FilterName.OSs] ? filters[FilterName.OSs].join() : ''}&source=${
                    filters && filters[FilterName.Sources] ? filters[FilterName.Sources].join() : ''
                  }&users=${filters && filters[FilterName.AccountManagers] ? filters[FilterName.AccountManagers].join() : ''}&lps=${
                    filters && filters[FilterName.LPs] ? filters[FilterName.LPs].join() : ''
                  }&country=${filters && filters[FilterName.Countries] ? filters[FilterName.Countries].join(',') : ''}&advertiser_comp=${
                    filters && filters[FilterName.TrafficCompanies] ? filters[FilterName.TrafficCompanies].join(',') : ''
                  }&product_comp=${filters && filters[FilterName.ProductCompanies] ? filters[FilterName.ProductCompanies].join(',') : ''}`}
                >
                  <BarChartOutlined /> &nbsp; Graph view
                </Link>
                {/** {exportFinished ? <Icon type="right-square" /> : null}*/}
              </Button>
            </Col>

            <Col>
              <Button
                className="export-btn"
                type="primary"
                ghost
                onClick={() => onExport({ filters: getFiltersWithoutDateRange(filters), checkedColumns, dateRange, type: 'csv' })}
                icon={<DownloadOutlined />}
                loading={loadingExportButton}
              >
                Export data
              </Button>
            </Col>
            <Col>
              <Button
                style={{ display: 'flex' }}
                className="export-btn"
                type="primary"
                ghost
                onClick={() => onExport({ filters: getFiltersWithoutDateRange(filters), checkedColumns, dateRange, type: 'google_sheet' })}
                loading={loadingExportButton}
              >
                <img alt="" width="14" src="/images/google_sheets.png" style={{ alignSelf: 'center', marginRight: '8px' }} />
                Open in Google Sheets
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {filtersLoading ? (
        <Spin size="large" />
      ) : displayFilters ? (
        <div className={styles.filtersWrapper}>
          <Row gutter={32}>
            <Col xs={24} sm={24} md={16} lg={12} xl={8} xxl={5}>
              <span className={styles.spanMarginBottom}>Date range</span>
              <RangePicker
                format="DD.MM.YYYY"
                renderExtraFooter={() => {
                  return (
                    <ul className="ant-picker-ranges">
                      {dateRangePresets.map(({ value: key, text }) => (
                        <li className="ant-picker-preset" key={key}>
                          <span
                            className="ant-tag ant-tag-blue"
                            onClick={() => {
                              setDateRange({ from: dateRangePresetDates[key][0], to: dateRangePresetDates[key][1] });
                              handleFiltersChange({ ...filters, date_range: key });
                              setIsRangePickerOpen(false);
                            }}
                          >
                            {text}
                          </span>
                        </li>
                      ))}
                    </ul>
                  );
                }}
                onChange={(momentDates, dates) => {
                  setDateRange({ from: momentDates ? momentDates[0] : null, to: momentDates ? momentDates[1] : null });
                  handleFiltersChange({ ...filters, date_range: undefined });
                }}
                value={[dateRange.from, dateRange.to]}
                style={{ width: '100%' }}
                open={isRangePickerOpen}
                onOpenChange={setIsRangePickerOpen}
              />
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>
                Channels
                <span></span>
              </span>

              <VirtualizedSelect
                options={mediaCampaignsService.generateOptions(channels)}
                onChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.Channels]: value });
                }}
                channels={channels}
                multi={true}
                diffPasteHandler={true}
                selectValue={filters[FilterName.Channels]}
                placeholder={'Select channels'}
                loading={channelsLoading}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Countries</span>
              <IncludeExcludeSelect
                options={countriesService.generateOptions(countries)}
                mode="multiple"
                value={filters[FilterName.Countries]}
                placeholder={'Select countries'}
                loading={countriesLoading}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.Countries)}
                onValuesChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.Countries]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.Countries)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Products</span>
              <IncludeExcludeSelect
                options={productsService.generateOptions(products)}
                mode="multiple"
                value={filters[FilterName.Products]}
                placeholder={'Select products'}
                loading={productsLoading}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.Products)}
                onValuesChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.Products]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.Products)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Rule types</span>
              <IncludeExcludeSelect
                options={ruleType}
                mode="multiple"
                value={filters[FilterName.RuleTypes]}
                placeholder={'Select rule types'}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.RuleTypes)}
                onValuesChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.RuleTypes]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.RuleTypes)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>OSs</span>
              <IncludeExcludeSelect
                options={osService.generateOptionsForFilters(os)}
                mode="tags"
                value={filters[FilterName.OSs]}
                placeholder={'Select os/s'}
                loading={osLoading}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.OSs)}
                onValuesChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.OSs]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.OSs)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>OS Versions</span>
              <IncludeExcludeSelect
                mode="tags"
                value={filters[FilterName.OSVersions]}
                placeholder={'Select os versions'}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.OSVersions)}
                onValuesChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.OSVersions]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.OSVersions)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Browsers</span>
              <IncludeExcludeSelect
                options={browsersService.generateOptionsWithNameAsValue(browsers)}
                mode="multiple"
                value={filters[FilterName.Browsers]}
                placeholder={'Select browsers'}
                loading={browsersLoading}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.Browsers)}
                onValuesChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.Browsers]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.Browsers)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Browser Versions</span>
              <IncludeExcludeSelect
                mode="tags"
                value={filters[FilterName.BrowserVersions]}
                placeholder={'Select browser versions'}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.BrowserVersions)}
                onValuesChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.BrowserVersions]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.BrowserVersions)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Device Types</span>
              <IncludeExcludeSelect
                options={deviceTypesService.generateOptionsForStatsFilter(deviceTypes)}
                mode="multiple"
                value={filters[FilterName.DeviceTypes]}
                placeholder={'Select device types'}
                loading={deviceTypesLoading}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.DeviceTypes)}
                onValuesChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.DeviceTypes]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.DeviceTypes)}
              />
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Identities</span>
              <VirtualizedSelect
                options={identityService.generateOptions(identities)}
                onChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.Identities]: value });
                }}
                multi={true}
                selectValue={filters[FilterName.Identities]}
                placeholder={'Select identities'}
                loading={identitiesLoading}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Account managers</span>
              <IncludeExcludeSelect
                options={accountsService.generateOptions(users)}
                mode="multiple"
                value={filters[FilterName.AccountManagers]}
                placeholder={'Select account managers'}
                loading={usersLoading}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.AccountManagers)}
                onValuesChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.AccountManagers]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.AccountManagers)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Landing pages</span>
              <IncludeExcludeSelect
                options={landingPagesService.generateOptions(lps)}
                mode="multiple"
                value={filters[FilterName.LPs]}
                placeholder={'Select landing pages'}
                loading={lpsLoading}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.LPs)}
                onValuesChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.LPs]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.LPs)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Product Companies</span>
              <IncludeExcludeSelect
                options={companiesService.generateOptions(productCompany)}
                mode="multiple"
                value={filters[FilterName.ProductCompanies]}
                placeholder={'Select product companies'}
                loading={productCompanyLoading}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.ProductCompanies)}
                onValuesChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.ProductCompanies]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.ProductCompanies)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Traffic Companies</span>
              <IncludeExcludeSelect
                options={companiesService.generateOptions(companies)}
                mode="multiple"
                value={filters[FilterName.TrafficCompanies]}
                placeholder={'Select traffic companies'}
                loading={companiesLoading}
                isExcluded={!!filters[FilterName.Excluded].find((filterName) => filterName === FilterName.TrafficCompanies)}
                onValuesChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.TrafficCompanies]: value });
                }}
                onExcludedChange={(isExcluded) => handleExcludedChange(isExcluded, FilterName.TrafficCompanies)}
              />
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <div className={styles.sourcesHeadingWrapper}>
                <span className={styles.spanMarginBottom}>Sources</span>

                <Radio.Group
                  value={filters[FilterName.SourcesSearchType]}
                  onChange={(e) => {
                    handleFiltersChange({ ...filters, [FilterName.SourcesSearchType]: e.target.value });
                  }}
                >
                  <Tooltip title="Contains">
                    <Radio.Button value="contains">
                      <img src={`/images/${containsSearchTypeImageName}.png`} alt="Contains" />
                    </Radio.Button>
                  </Tooltip>

                  <Tooltip title="Exact">
                    <Radio.Button value="exact">
                      <img src={`/images/${exactSearchTypeImageName}.png`} alt="Exact" />
                    </Radio.Button>
                  </Tooltip>
                </Radio.Group>
              </div>

              <Select
                style={{ width: '100%' }}
                mode="tags"
                onChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.Sources]: value });
                }}
                value={filters[FilterName.Sources]}
                allowClear
                tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                placeholder={'Source'}
              >
                {null}
              </Select>
            </Col>

            {showSourceFilters && (
              <>
                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                  <span className={styles.spanMarginBottom}>QS 1</span>
                  <Select
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={(value) => {
                      handleFiltersChange({ ...filters, [FilterName.Sources1]: value });
                    }}
                    value={filters[FilterName.Sources1]}
                    allowClear
                    tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                    placeholder={'QS 1'}
                  >
                    {null}
                  </Select>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                  <span className={styles.spanMarginBottom}>QS 2</span>
                  <Select
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={(value) => {
                      handleFiltersChange({ ...filters, [FilterName.Sources2]: value });
                    }}
                    value={filters[FilterName.Sources2]}
                    allowClear
                    tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                    placeholder={'QS 2'}
                  >
                    {null}
                  </Select>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                  <span className={styles.spanMarginBottom}>QS 3</span>
                  <Select
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={(value) => {
                      handleFiltersChange({ ...filters, [FilterName.Sources3]: value });
                    }}
                    value={filters[FilterName.Sources3]}
                    allowClear
                    tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                    placeholder={'QS 3'}
                  >
                    {null}
                  </Select>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                  <span className={styles.spanMarginBottom}>QS 4</span>
                  <Select
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={(value) => {
                      handleFiltersChange({ ...filters, [FilterName.Sources4]: value });
                    }}
                    value={filters[FilterName.Sources4]}
                    allowClear
                    tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                    placeholder={'QS 4'}
                  >
                    {null}
                  </Select>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                  <span className={styles.spanMarginBottom}>QS 5</span>
                  <Select
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={(value) => {
                      handleFiltersChange({ ...filters, [FilterName.Sources5]: value });
                    }}
                    value={filters[FilterName.Sources5]}
                    allowClear
                    tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                    placeholder={'QS 5'}
                  >
                    {null}
                  </Select>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                  <span className={styles.spanMarginBottom}>QS 6</span>
                  <Select
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={(value) => {
                      handleFiltersChange({ ...filters, [FilterName.Sources6]: value });
                    }}
                    value={filters[FilterName.Sources6]}
                    allowClear
                    tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                    placeholder={'QS 6'}
                  >
                    {null}
                  </Select>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                  <span className={styles.spanMarginBottom}>QS 7</span>
                  <Select
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={(value) => {
                      handleFiltersChange({ ...filters, [FilterName.Sources7]: value });
                    }}
                    value={filters[FilterName.Sources7]}
                    allowClear
                    tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                    placeholder={'QS 7'}
                  >
                    {null}
                  </Select>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                  <span className={styles.spanMarginBottom}>QS 8</span>
                  <Select
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={(value) => {
                      handleFiltersChange({ ...filters, [FilterName.Sources8]: value });
                    }}
                    value={filters[FilterName.Sources8]}
                    allowClear
                    tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                    placeholder={'QS 8'}
                  >
                    {null}
                  </Select>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                  <span className={styles.spanMarginBottom}>QS 9</span>
                  <Select
                    style={{ width: '100%' }}
                    mode="tags"
                    onChange={(value) => {
                      handleFiltersChange({ ...filters, [FilterName.Sources9]: value });
                    }}
                    value={filters[FilterName.Sources9]}
                    allowClear
                    tokenSeparators={['\r\n', '\n', ',', ';', ' ']}
                    placeholder={'QS 9'}
                  >
                    {null}
                  </Select>
                </Col>
              </>
            )}

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Publisher Modules</span>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                onChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.PublisherModules]: value });
                }}
                value={filters[FilterName.PublisherModules]}
                placeholder={'Select publisher module'}
                allowClear
              >
                {generateOptionsFromObject(PublisherModules, firstLetterUpperCase)}
              </Select>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Campaign Types</span>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                onChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.CampaignTypes]: value });
                }}
                value={filters[FilterName.CampaignTypes]}
                placeholder={'Select campaign type'}
                allowClear
              >
                {generateOptionsFromObject(CampaignType)}
              </Select>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Campaign Titles</span>
              <Select
                style={{ width: '100%' }}
                mode="tags"
                onChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.CampaignTitles]: value });
                }}
                value={filters[FilterName.CampaignTitles]}
                placeholder={'Type campaign title'}
                allowClear
              />
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Campaign Statuses</span>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                onChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.CampaignStatuses]: value });
                }}
                value={filters[FilterName.CampaignStatuses]}
                placeholder={'Select campaign status'}
                loading={statusesLoading}
                allowClear
              >
                {generateSelectOptionsWithIdValues(statuses)}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Company category</span>
              <Select
                value={filters[FilterName.CompanyCategory]}
                placeholder={'Select company category'}
                allowClear
                style={{ width: '100%' }}
                onChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.CompanyCategory]: value });
                }}
                showSearch
                optionFilterProp="data-searchvalue"
              >
                {generateOptionsFromObjectKeyValue(STATS_CATEGORIES)}
              </Select>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Brought by</span>
              <Select
                options={accountsService.generateOptions(users)}
                mode="multiple"
                value={filters[FilterName.BroughtBy]}
                placeholder={'Brought by'}
                loading={usersLoading}
                style={{ width: '100%' }}
                onChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.BroughtBy]: value });
                }}
                showSearch
                filterOption={filterOptionsByLabelAndValue}
              ></Select>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Tags</span>
              <Select
                style={{ width: '100%' }}
                value={filters[FilterName.Tags]}
                placeholder={'Select tag'}
                allowClear
                loading={tagsLoading}
                onChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.Tags]: value });
                }}
                showSearch
                optionFilterProp="data-searchvalue"
              >
                {generateSelectOptionsWithIdValues(tags)}
              </Select>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <span className={styles.spanMarginBottom}>Domains</span>
              <Select
                style={{ width: '100%' }}
                mode="tags"
                onChange={(value) => {
                  handleFiltersChange({ ...filters, [FilterName.Domains]: value });
                }}
                value={filters[FilterName.Domains]}
                placeholder={'Type domains'}
                allowClear
              />
            </Col>
          </Row>

          <Row gutter={32}>
            <Col span={12}>
              <Button onClick={() => handleUpdate()} type="primary">
                Update
              </Button>
            </Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Row gutter={32}>
                <Col span={8}>
                  <FilterSwitch
                    label="Include Errors"
                    checked={!!filters[FilterName.IncludeErrors]}
                    onChange={(value) => {
                      handleFiltersChange({ ...filters, [FilterName.IncludeErrors]: value ? 1 : 0 });
                    }}
                  />
                </Col>
                <Col span={8}>
                  <FilterSwitch
                    label="No profit"
                    checked={!!filters[FilterName.NonProfit]}
                    onChange={(value) => {
                      handleFiltersChange({ ...filters, [FilterName.NonProfit]: value ? 1 : 0 });
                    }}
                  />
                </Col>
                <Col span={8}>
                  <FilterSwitch
                    label="QS filters"
                    checked={showSourceFilters}
                    onChange={setShowSourceFilters}
                    className={styles.sourceFiltersSwitch}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : null}
    </div>
  );
};
