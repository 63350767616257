import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Form, Input, InputNumber, Checkbox, Button, Modal, Popover, DatePicker, Row, Col } from 'antd';
import { UpOutlined, DownOutlined, CloseOutlined, PlusOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';

import { getSelectOptionsWithIdValues } from '../pages/media-campaigns/utils/options';
import { getSelectOptionsWithTooltip, getAccountsSelectOptions, getSelectOptionsWithExtendedTooltip } from '../pages/lists/utils/options';
import { generateCampaignPatternTypeSelectOptions, generateCountriesOptions, generateSelectOptionsWithIdValues } from '../utils/options';

import styles from './TargetingForm.module.css';
import { Select } from './Select';
import { landingPagesService } from '../services/landingPages';
import { LpPreview, PreviewLpSelect } from './PreviewLpSelect';
import { SetupTypes } from '../services/media-campaigns';
import { defaultModalFields, SetupRepeatModal } from './SetupRepeatModal';
import { repeatPeriodTypes } from '../pages/media-campaigns/utils/enums';
import moment from 'moment';

import { withRefetching } from './SelectWithSideLoader';
import {
  getAccounts,
  getBrowserList,
  getBrowsersAndBrowsersLists,
  getCountriesAndCountriesLists,
  getCountryList,
  getDeviceTypeList,
  getDeviceTypeLists,
  getExternalUrls,
  getLandingPages,
  getOSAndOSLists,
  getOSList,
  getProducts,
  getSetupTypes,
  getSubidList,
  getSubidLists,
  useCustomQuery
} from '../utils/hooks/queries';
import { useEffectTrigger } from '../utils/hooks';
import { CustomPixelInput } from './CustomPixelInput';
import { DEFAULT_AUTOMATION_RULES_VALUE, DEFAULT_S2S_ADVANCED_VALUE } from '../utils/targeting';

import { AutomationRulesTargetingSection } from './AutomationRulesTargetingSection';

const { OptGroup } = Select;
const { RangePicker } = DatePicker;

const repeatMessages = {
  doesNotRepeat: '(Does not repeat)',
  repeatsDaily: '(Repeats daily)',
  repeatsWeekly: '(Repeats weekly)'
};

const Direction = {
  Up: 'up',
  Down: 'down'
};

const SelectWithRefetching = withRefetching(Select);

export const TargetingForm = ({ form, patternDetails, disabled, formChangedTrigger, isListForm }) => {
  const [patternsTotalPercentage, setPatternsTotalPercentage] = useState({});
  const [selectedSetupTypes, setSelectedSetupTypes] = useState({});
  const [landingPagesSelectDropdown, setLandingPagesSelectDropdown] = useState({});
  const [showSchedulers, setShowSchedulers] = useState({});
  const [showSetupRepeatModal, setShowSetupRepeatModal] = useState({});
  const [schedulerRepeatMessages, setSchedulerRepeatMessages] = useState({});
  const [areAllPatternsSelected, setAreAllPatternsSelected] = useState(false);
  const [isDeleteSelectedPatternsDisabled, setIsDeleteSelectedPatternsDisabled] = useState(false);
  const [dynamicPixelOptimisationPerSetup, setDynamicPixelOptimisationPerSetup] = useState({});

  const [originalPatternIndices, setOriginalPatternIndices] = useState([]);
  const [gpssPatternIndicesByOriginalPatternIndex, setGpssPatternIndicesByOriginalPatternIndex] = useState({});
  const [setupsGpssRuleByParentPatternIndex, setSetupsGpssRuleByParentPatternIndex] = useState({});
  // const [s2sConfiguration, setS2sConfiguration] = useState({});
  const [orderChangeTrigger, activateOrderChangeTrigger] = useEffectTrigger();

  const { isLoading: allAccountsLoading, isFetching: allAccountsRefetching, data: allAccounts } = useCustomQuery(getAccounts);

  const {
    isFetching: allCountryAndCountryListsRefetching,
    isLoading: allCountryAndCountryListsLoading,

    data: allCountryAndCountryLists
  } = useCustomQuery(getCountriesAndCountriesLists, { fields: 'id,name,img' });

  const {
    isLoading: allOSAndOSListsLoading,
    isFetching: allOSAndOSListsRefetching,
    data: allOSAndOSLists
  } = useCustomQuery(getOSAndOSLists, { fields: 'id,name,img' });

  const {
    isLoading: allBrowsersAndBrowsersListsLoading,
    isFetching: allBrowsersAndBrowsersListsRefetching,
    data: allBrowsersAndBrowsersLists
  } = useCustomQuery(getBrowsersAndBrowsersLists, { fields: 'id,name,img' });

  const {
    isLoading: allSubidListsLoading,
    isFetching: allSubidListsRefetching,
    data: allSubidLists
  } = useCustomQuery(getSubidLists, { fields: 'id,name,exclude' });

  const {
    isLoading: allDeviceTypeListsLoading,
    isFetching: allDeviceTypeListsRefetching,
    data: allDeviceTypeLists
  } = useCustomQuery(getDeviceTypeLists, { fields: 'id,name,exclude' });

  const { isLoading: allSetupTypesLoading, isFetching: allSetupTypesRefetching, data: allSetupTypes } = useCustomQuery(getSetupTypes);

  const {
    isLoading: allProductsLoading,
    isFetching: allProductsRefetching,
    data: allProducts
  } = useCustomQuery(getProducts, { fields: 'id,title,product_lps' });

  const { isLoading: allLandingPagesLoading, data: allLandingPages } = useCustomQuery(getLandingPages, {
    fields: 'id,title,date_edited,products'
  });

  const {
    isLoading: allExternalURLsLoading,
    isFetching: allExternalURLsRefetching,
    data: allExternalURLs
  } = useCustomQuery(getExternalUrls);

  const [countryLists, countries] = useMemo(() => {
    const countryLists = allCountryAndCountryLists.results
      ? allCountryAndCountryLists.results.slice(0, allCountryAndCountryLists.lists_count)
      : [];

    const countries = allCountryAndCountryLists.results
      ? allCountryAndCountryLists.results.slice(allCountryAndCountryLists.lists_count)
      : [];

    return [countryLists, countries];
  }, [allCountryAndCountryLists]);

  const [osLists, oss] = useMemo(() => {
    const osLists = allOSAndOSLists.results ? allOSAndOSLists.results.slice(0, allOSAndOSLists.lists_count) : [];

    const oss = allOSAndOSLists.results ? allOSAndOSLists.results.slice(allOSAndOSLists.lists_count) : [];

    return [osLists, oss];
  }, [allOSAndOSLists]);

  const [browserLists, browsers] = useMemo(() => {
    const browserLists = allBrowsersAndBrowsersLists.results
      ? allBrowsersAndBrowsersLists.results.slice(0, allBrowsersAndBrowsersLists.lists_count)
      : [];

    const browsers = allBrowsersAndBrowsersLists.results
      ? allBrowsersAndBrowsersLists.results.slice(allBrowsersAndBrowsersLists.lists_count)
      : [];

    return [browserLists, browsers];
  }, [allBrowsersAndBrowsersLists]);

  const handleFormChange = useCallback(() => {
    const patternFormListData = [...form.getFieldValue('patternFormList')];

    const showSchedulersLocal = {};
    const repeatMessagesLocal = {};
    const percentages = {};
    const landingPagesDropdown = {};
    const selectedTypes = {};
    const dynamicPixelOptimisationPerSetupLocal = {};
    let hasSelectedPattern = false;

    patternFormListData.forEach((patternData, patternIndex) => {
      const { isPatternSelected, showScheduler, repeatEveryNumber, repeatEveryPeriod, patternSetupFormList } = patternData;

      let totalPercentage = 0;
      let hasEmptyRow = false;

      // setting scheduler data
      showSchedulersLocal[patternIndex] = !!showScheduler;
      repeatMessagesLocal[patternIndex] = getRepeatMessage(repeatEveryNumber, repeatEveryPeriod);

      if (isPatternSelected) {
        hasSelectedPattern = true;
      }

      if (patternSetupFormList) {
        patternSetupFormList.forEach((patternSetupRowData, patternSetupIndex) => {
          const { type, product, percentage, dynamicPixelOptimisation } = patternSetupRowData;

          // caluclate pattern total percentage
          if (patternSetupRowData !== undefined && patternSetupRowData !== '') {
            totalPercentage += percentage;
          } else {
            hasEmptyRow = true;
          }

          if (hasEmptyRow) {
            totalPercentage = undefined;
          }

          percentages[patternIndex] = totalPercentage;

          // generate landing pages dropdown for pattern setup row
          if (!product) {
            landingPagesDropdown[patternIndex] = { ...landingPagesDropdown[patternIndex], [patternSetupIndex]: allLandingPages };
          } else {
            const productDetails = allProducts?.find(({ id }) => product === id);
            const productLandingPageIds = productDetails?.product_lps ?? [];

            let productLandingPages = [];
            const defaultLandingPages = [];

            allLandingPages.forEach((lp) => {
              if (!lp.products.length) {
                defaultLandingPages.push(lp);
              } else {
                for (let i = 0; i < productLandingPageIds.length; i++) {
                  if (lp.id === productLandingPageIds[i]) {
                    productLandingPages.push(lp);
                    break;
                  }
                }
              }
            });

            landingPagesDropdown[patternIndex] = {
              ...landingPagesDropdown[patternIndex],
              [patternSetupIndex]: productLandingPages.length ? [...productLandingPages, ...defaultLandingPages] : allLandingPages
            };
          }

          // setting pattern setup row type
          selectedTypes[patternIndex] = { ...selectedTypes[patternIndex], [patternSetupIndex]: type };
          // setting dynamic pixel optimisation per row
          dynamicPixelOptimisationPerSetupLocal[patternIndex] = {
            ...dynamicPixelOptimisationPerSetupLocal[patternIndex],
            [patternSetupIndex]: !!dynamicPixelOptimisation
          };
        });
      }
    });
    setShowSchedulers(showSchedulersLocal);
    setSchedulerRepeatMessages(repeatMessagesLocal);
    setPatternsTotalPercentage(percentages);
    setLandingPagesSelectDropdown(landingPagesDropdown);
    setSelectedSetupTypes(selectedTypes);
    setIsDeleteSelectedPatternsDisabled(!hasSelectedPattern);
    setDynamicPixelOptimisationPerSetup(dynamicPixelOptimisationPerSetupLocal);
  }, [form, allLandingPages, allProducts]);

  // TODO: Add comments for this function
  const handleNumberInputOrderChange = (originalPatternIndex, wantedPositionIndex, moveCallback) => {
    let validWantedPositionIndex = wantedPositionIndex;

    if (validWantedPositionIndex < 0) {
      validWantedPositionIndex = 0;
    } else if (validWantedPositionIndex > originalPatternIndices.length - 1) {
      validWantedPositionIndex = originalPatternIndices.length - 1;
    }

    const gpssIndicesToMove = gpssPatternIndicesByOriginalPatternIndex[originalPatternIndex];

    const originalPatternIndexArrayIndex = originalPatternIndices.indexOf(originalPatternIndex);

    if (originalPatternIndexArrayIndex > validWantedPositionIndex) {
      const newOrderStateOriginalPatternIndexBehind = originalPatternIndices[validWantedPositionIndex];
      const patternIndexBehind =
        gpssPatternIndicesByOriginalPatternIndex[newOrderStateOriginalPatternIndexBehind] &&
        gpssPatternIndicesByOriginalPatternIndex[newOrderStateOriginalPatternIndexBehind].length
          ? gpssPatternIndicesByOriginalPatternIndex[newOrderStateOriginalPatternIndexBehind][0]
          : newOrderStateOriginalPatternIndexBehind;
      [...gpssIndicesToMove, originalPatternIndex].forEach((index, loopIndex) => {
        moveCallback(index, patternIndexBehind + loopIndex);
      });
    } else if (originalPatternIndexArrayIndex < validWantedPositionIndex) {
      const newOrderStateOriginalPatternIndexInFront = originalPatternIndices[validWantedPositionIndex];
      [...gpssIndicesToMove, originalPatternIndex].reverse().forEach((index, loopIndex) => {
        moveCallback(index, newOrderStateOriginalPatternIndexInFront - loopIndex);
      });
    }
  };

  const handleArrowOrderChange = useCallback(
    (originalPatternIndex, direction, moveCallback) => {
      const gpssPatternIndicesFromOriginalPattern = gpssPatternIndicesByOriginalPatternIndex[originalPatternIndex];

      if (direction === Direction.Up && originalPatternIndices?.[0] !== originalPatternIndex) {
        const neighbourOriginalPatternIndex = originalPatternIndices[originalPatternIndices.indexOf(originalPatternIndex) - 1];
        const gpssIndicesFromNeighbourOriginalPattern = gpssPatternIndicesByOriginalPatternIndex[neighbourOriginalPatternIndex];
        const positionsToMoveOriginalAndItsGpssPatterns = -(gpssIndicesFromNeighbourOriginalPattern.length + 1);

        [...gpssPatternIndicesFromOriginalPattern, originalPatternIndex].forEach((index) => {
          moveCallback(index, index + positionsToMoveOriginalAndItsGpssPatterns);
        });
      } else if (direction === Direction.Down && originalPatternIndices?.[originalPatternIndices.length - 1] !== originalPatternIndex) {
        const neighbourOriginalPatternIndex = originalPatternIndices[originalPatternIndices.indexOf(originalPatternIndex) + 1];
        const gpssIndicesFromNeighbourOriginalPattern = gpssPatternIndicesByOriginalPatternIndex[neighbourOriginalPatternIndex];
        const positionsToMoveOriginalAndItsGpssPatterns = gpssIndicesFromNeighbourOriginalPattern.length + 1;

        [...gpssPatternIndicesFromOriginalPattern, originalPatternIndex].reverse().forEach((index) => {
          moveCallback(index, index + positionsToMoveOriginalAndItsGpssPatterns);
        });
      }
    },
    [gpssPatternIndicesByOriginalPatternIndex, originalPatternIndices]
  );

  const fillPatternIndicesData = useCallback(() => {
    const patternFormListData = [...form.getFieldValue('patternFormList')];
    const originalPatternIndicesLocal = [];
    const gpssPatternIndicesByOriginalPatternIndexLocal = {};
    const setupsGpssRuleByParentPatternIndexLocal = {};

    let gpssPatternIndices = [];
    let setupsGpssRule = [];

    patternFormListData.forEach((patternData, patternIndex) => {
      if (patternData) {
        const { origin, patternSetupFormList } = patternData;

        if (origin) {
          gpssPatternIndices.push(patternIndex);

          patternSetupFormList.forEach(({ gpssRule }) => {
            setupsGpssRule.push(gpssRule || null);
          });
          setupsGpssRuleByParentPatternIndexLocal[patternIndex] = setupsGpssRule;
          setupsGpssRule = [];
        } else {
          originalPatternIndicesLocal.push(patternIndex);

          gpssPatternIndicesByOriginalPatternIndexLocal[patternIndex] = gpssPatternIndices;
          gpssPatternIndices = [];
        }
      }
    });

    setOriginalPatternIndices(originalPatternIndicesLocal);
    setGpssPatternIndicesByOriginalPatternIndex(gpssPatternIndicesByOriginalPatternIndexLocal);
    setSetupsGpssRuleByParentPatternIndex(setupsGpssRuleByParentPatternIndexLocal);
  }, [form]);

  useEffect(() => {
    fillPatternIndicesData();
  }, [orderChangeTrigger, fillPatternIndicesData]);

  useEffect(() => {
    handleFormChange();
  }, [formChangedTrigger, handleFormChange]);

  useEffect(() => {
    const patternFormListData = cloneDeep(form.getFieldValue('patternFormList'));

    const newPatternFormListData = patternFormListData.map((patternData) => ({
      ...patternData,
      isPatternSelected: !!areAllPatternsSelected
    }));

    setIsDeleteSelectedPatternsDisabled(!!areAllPatternsSelected);
    form.setFieldsValue({ patternFormList: newPatternFormListData });
  }, [form, areAllPatternsSelected]);

  const deleteSelectedPatterns = () => {
    const patternFormListData = cloneDeep(form.getFieldValue('patternFormList'));

    const newPatternFormListData = patternFormListData.filter(({ isPatternSelected }) => !isPatternSelected);

    form.setFieldsValue({ patternFormList: newPatternFormListData });
    setAreAllPatternsSelected(false);
  };

  const confirmDeleteSelected = () => {
    Modal.confirm({
      maskClosable: true,
      title: 'Are you sure you want to delete selected patterns?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: deleteSelectedPatterns,
      onCancel() {}
    });
  };

  const patternClone = (patternIndex, addFn) => {
    const patternData = { ...form.getFieldValue('patternFormList')[patternIndex] };

    delete patternData.patternId;

    if (patternData.patternSetupFormList) {
      patternData.patternSetupFormList.forEach((setupData) => {
        setupData = { ...setupData };
        delete setupData.patternSetupId;
        return setupData;
      });
    }

    // addFn(patternData); // Will add pattern on the bottom
    addFn(patternData, patternIndex++); // Will add pattern under pattern we want to clone
  };

  const patternSetupClone = (patternIndex, patternSetupIndex, addFn, length) => {
    const patternSetupRowData = { ...form.getFieldValue('patternFormList')[patternIndex].patternSetupFormList[patternSetupIndex] };

    delete patternSetupRowData.patternSetupId;

    addFn(patternSetupRowData); // Will add setup row on the bottom
    // addFn(patternSetupRowData, patternSetupIndex++); // Will add setup row under row we want to clone
  };

  const getRepeatMessage = (repeatEveryNumber, repeatEveryPeriod) => {
    return !repeatEveryNumber
      ? repeatMessages.doesNotRepeat
      : repeatEveryPeriod === repeatPeriodTypes.DAY
      ? repeatMessages.repeatsDaily
      : repeatMessages.repeatsWeekly;
  };

  const isDisabled = (patternIndex, setupIndex, type) =>
    selectedSetupTypes[patternIndex]
      ? selectedSetupTypes[patternIndex][setupIndex]
        ? selectedSetupTypes[patternIndex][setupIndex] === type
        : false
      : false;

  const removePatternAndUpdate = (field, remove) => {
    remove(field.name);
    activateOrderChangeTrigger();
  };

  const updateOldValues = (setterCallback, key, newValue) => {
    setterCallback((oldValues) => ({
      ...oldValues,
      [key]: newValue
    }));
  };

  const generateStyles = (listName) => {
    if (listName) {
      const value = form.getFieldValue(['patternFormList', ...listName]);
      if (value && value.split(':')[0] === 'list') {
        return styles.selectListStyle;
      }
    }
    return '';
  };

  const evenlyDistributePercentage = (patternIndex) => {
    const patternSetupRowsData = JSON.parse(JSON.stringify(form.getFieldValue('patternFormList')[patternIndex].patternSetupFormList)); // Deep clone form patternSetupFormList array
    const patternSetupRowsCount = patternSetupRowsData.length;
    const lowerValue = Math.floor(100 / patternSetupRowsCount); // minimum percentage per row
    const remainder = 100 % patternSetupRowsCount; // remaining percentage

    for (let i = 0; i < patternSetupRowsCount; i++) {
      patternSetupRowsData[i].percentage = lowerValue;
    }

    if (remainder) {
      // Allocate remaining percentage -> 1% per row
      const higherValue = lowerValue + 1;
      for (let i = 0; i < remainder; i++) {
        patternSetupRowsData[i].percentage = higherValue;
      }
    }

    form.setFields([{ name: ['patternFormList', patternIndex, 'patternSetupFormList'], value: patternSetupRowsData }]);
  };

  return (
    <div className={styles.targetingContent}>
      <div>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
      </div>

      {isListForm ? (
        <div className={styles.targetingFormFieldsWrapper}>
          <div className={styles.formItemWrapper3}>
            <span>Targeting List Name</span>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter list name!'
                }
              ]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className={styles.formItemWrapper3}>
            <span>Assigned</span> <br />
            <Form.Item name="assigned">
              <SelectWithRefetching
                mode="multiple"
                allowClear
                optionFilterProp="data-searchvalue"
                disabled={disabled}
                loading={allAccountsLoading}
                refetching={allAccountsRefetching}
              >
                {getAccountsSelectOptions(allAccounts)}
              </SelectWithRefetching>
            </Form.Item>
          </div>

          <div>
            <Form.Item name="privateList" valuePropName="checked">
              <Checkbox disabled={disabled}>Private</Checkbox>
            </Form.Item>
          </div>
        </div>
      ) : null}

      <div className={disabled ? styles.disabledForm : ''}>
        <div className={styles.selectAllPatternsAndDeleteSelectedWrapper} style={{ display: disabled ? 'none' : 'flex' }}>
          <Checkbox
            className={styles.selectAllPatternsCheckbox}
            checked={areAllPatternsSelected}
            onChange={(e) => setAreAllPatternsSelected(e.target.checked)}
          >
            Select All Patterns
          </Checkbox>

          <Button
            className={styles.deleteSelectedBtn}
            type="primary"
            disabled={isDeleteSelectedPatternsDisabled}
            onClick={confirmDeleteSelected}
          >
            Delete Selected
          </Button>
        </div>

        <div>
          <Form.List name="patternFormList" shouldUpdate>
            {(fields, { add: addPattern, remove, move }) => {
              return (
                <div className={styles.patternFormListWrapper}>
                  {fields.map((field, index) => (
                    <div key={field.key} style={{ position: 'relative' }}>
                      <div
                        className={styles.disabledGpssPattern}
                        style={{
                          display:
                            Object.keys(setupsGpssRuleByParentPatternIndex).find(
                              (originalPatternIndex) => index === Number(originalPatternIndex)
                            ) !== undefined // Compare to undefined, because of index 0: if index = 0 .find() can possibly return 0, which is falsy
                              ? 'block'
                              : 'none'
                        }}
                      ></div>
                      <div
                        key={field.key}
                        className={`${styles.targetingPatternWrapper} ${
                          Number(form.getFieldValue(['patternFormList', index, 'origin'])) ? styles.gpssPatternWrapper : ''
                        }`}
                      >
                        <div className={styles.patternRowFieldsOuterWrapper}>
                          <div className={styles.patternRowFieldsInnerWrapper}>
                            <div className={styles.patternSelectAndMoverWrapper}>
                              <div className={styles.patternSelect}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'isPatternSelected']}
                                  fieldKey={[field.fieldKey, 'isPatternSelected']}
                                  valuePropName="checked"
                                >
                                  <Checkbox>Pattern</Checkbox>
                                </Form.Item>
                              </div>

                              <div className={styles.movePatternWrapper}>
                                {originalPatternIndices.indexOf(index) + 1 ? (
                                  <InputNumber
                                    className={styles.patternIndex}
                                    onBlur={(e) => {
                                      const newPosition = Number(e.target.value);
                                      handleNumberInputOrderChange(index, newPosition - 1, move);
                                      activateOrderChangeTrigger();
                                    }}
                                    onPressEnter={(e) => {
                                      const newPosition = Number(e.target.value);
                                      handleNumberInputOrderChange(index, newPosition - 1, move);
                                      activateOrderChangeTrigger();
                                    }}
                                    onFocus={(e) => {
                                      e.target.select();
                                    }}
                                    maxLength={fields.length}
                                    minLength={1}
                                    value={originalPatternIndices.indexOf(index) + 1}
                                  />
                                ) : null}

                                <div
                                  className={styles.movePattern}
                                  onClick={() => {
                                    handleArrowOrderChange(index, Direction.Up, move);
                                    activateOrderChangeTrigger();
                                  }}
                                >
                                  <UpOutlined />
                                </div>

                                <div
                                  className={styles.movePattern}
                                  onClick={() => {
                                    handleArrowOrderChange(index, Direction.Down, move);
                                    activateOrderChangeTrigger();
                                  }}
                                >
                                  <DownOutlined />
                                </div>

                                <div
                                  className={styles.deletePattern}
                                  onClick={() => {
                                    Modal.confirm({
                                      maskClosable: true,
                                      title: 'Are you sure you want to remove pattern?',
                                      okText: 'Yes',
                                      okType: 'danger',
                                      cancelText: 'No',
                                      onOk: () => removePatternAndUpdate(field, remove),
                                      onCancel() {}
                                    });
                                  }}
                                >
                                  <CloseOutlined />
                                </div>
                              </div>
                            </div>

                            <Row>
                              <Col span={24}>
                                <Row gutter={24}>
                                  <Col span={0}>
                                    <Form.Item {...field} name={[field.name, 'patternId']} fieldKey={[field.fieldKey, 'patternId']} hidden>
                                      <Input />
                                    </Form.Item>
                                  </Col>

                                  <Col span={0}>
                                    <Form.Item {...field} name={[field.name, 'origin']} fieldKey={[field.fieldKey, 'origin']} hidden>
                                      <Input />
                                    </Form.Item>
                                  </Col>

                                  <Col span={5}>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, 'countryOrCountryList']}
                                      fieldKey={[field.fieldKey, 'countryOrCountryList']}
                                      rules={[{ required: true, message: 'Please select country or country list!' }]}
                                      label="Country/Country list"
                                    >
                                      <SelectWithRefetching
                                        placeholder="Select Country/Country List"
                                        dropdownClassName={styles.selectDropdownWithImg}
                                        optionFilterProp="data-searchvalue"
                                        showSearch
                                        loading={allCountryAndCountryListsLoading}
                                        refetching={allCountryAndCountryListsRefetching}
                                        className={generateStyles([field.name, 'countryOrCountryList'])}
                                      >
                                        <OptGroup key="countryLists" label="Country Lists">
                                          {getSelectOptionsWithExtendedTooltip(countryLists, 'countries_list', false, getCountryList)}
                                        </OptGroup>

                                        <OptGroup key="countries" label="Countries">
                                          {generateCountriesOptions(countries)}
                                        </OptGroup>
                                      </SelectWithRefetching>
                                    </Form.Item>
                                  </Col>

                                  <Col span={4}>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, 'osOrOSList']}
                                      fieldKey={[field.fieldKey, 'osOrOSList']}
                                      rules={[{ required: true, message: 'Please select OS or OS list!' }]}
                                      label="OS/OS list"
                                    >
                                      <SelectWithRefetching
                                        placeholder="Select OS/OS List"
                                        dropdownClassName={styles.selectDropdownWithImg}
                                        optionFilterProp="data-searchvalue"
                                        showSearch
                                        loading={allOSAndOSListsLoading}
                                        refetching={allOSAndOSListsRefetching}
                                        className={generateStyles([field.name, 'osOrOSList'])}
                                      >
                                        <OptGroup key="osLists" label="OS Lists">
                                          {getSelectOptionsWithExtendedTooltip(osLists, 'operating_systems_list', false, getOSList)}
                                        </OptGroup>

                                        <OptGroup key="oss" label="OSs">
                                          {getSelectOptionsWithTooltip(oss)}
                                        </OptGroup>
                                      </SelectWithRefetching>
                                    </Form.Item>
                                  </Col>

                                  <Col span={5}>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, 'browserOrBrowserList']}
                                      fieldKey={[field.fieldKey, 'browserOrBrowserList']}
                                      rules={[{ required: true, message: 'Please select browser or browser list!' }]}
                                      label="Browser/Browser list"
                                    >
                                      <SelectWithRefetching
                                        placeholder="Select Browser/Browser List"
                                        dropdownClassName={styles.selectDropdownWithImg}
                                        optionFilterProp="data-searchvalue"
                                        showSearch
                                        loading={allBrowsersAndBrowsersListsLoading}
                                        refetching={allBrowsersAndBrowsersListsRefetching}
                                        className={generateStyles([field.name, 'browserOrBrowserList'])}
                                      >
                                        <OptGroup key="browserLists" label="Browser Lists">
                                          {getSelectOptionsWithExtendedTooltip(browserLists, 'browsers_list', false, getBrowserList)}
                                        </OptGroup>

                                        <OptGroup key="browsers" label="Browsers">
                                          {getSelectOptionsWithTooltip(browsers)}
                                        </OptGroup>
                                      </SelectWithRefetching>
                                    </Form.Item>
                                  </Col>

                                  <Col span={5}>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, 'subidList']}
                                      fieldKey={[field.fieldKey, 'subidList']}
                                      label="Subid list"
                                    >
                                      <SelectWithRefetching
                                        placeholder="Select Subid List"
                                        dropdownClassName={styles.selectDropdownWithImg}
                                        optionFilterProp="data-searchvalue"
                                        showSearch
                                        allowClear
                                        loading={allSubidListsLoading}
                                        refetching={allSubidListsRefetching}
                                      >
                                        {getSelectOptionsWithExtendedTooltip(allSubidLists, 'subids_list', true, getSubidList)}
                                      </SelectWithRefetching>
                                    </Form.Item>
                                  </Col>

                                  <Col span={5}>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, 'deviceTypeList']}
                                      fieldKey={[field.fieldKey, 'deviceTypeList']}
                                      rules={[{ required: true, message: 'Please select device type list!' }]}
                                      label="Device type list"
                                    >
                                      <SelectWithRefetching
                                        placeholder="Select Device Type List"
                                        dropdownClassName={styles.selectDropdownWithImg}
                                        optionFilterProp="data-searchvalue"
                                        showSearch
                                        allowClear
                                        loading={allDeviceTypeListsLoading}
                                        refetching={allDeviceTypeListsRefetching}
                                      >
                                        {getSelectOptionsWithExtendedTooltip(
                                          allDeviceTypeLists,
                                          'device_type_list',
                                          true,
                                          getDeviceTypeList
                                        )}
                                      </SelectWithRefetching>
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <div className={styles.schedulerFieldsWrapper}>
                                  <div>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, 'showScheduler']}
                                      fieldKey={[field.fieldKey, 'showScheduler']}
                                      valuePropName="checked"
                                    >
                                      <Checkbox>Timeframe</Checkbox>
                                    </Form.Item>
                                  </div>

                                  {showSchedulers[index] && (
                                    <>
                                      <div className={styles.scheduleRangeAndUTCWrapper}>
                                        <div className={styles.scheduledRangeFieldWrapper}>
                                          <Form.Item
                                            {...field}
                                            name={[field.name, 'scheduledRange']}
                                            fieldKey={[field.fieldKey, 'scheduledRange']}
                                            rules={[{ required: showSchedulers[index], message: 'Please pick schedule range!' }]}
                                          >
                                            <RangePicker
                                              showTime
                                              placeholder={['Schedule Start', 'Schedule End']}
                                              disabledDate={(current) => current < moment().startOf('day')}
                                            />
                                          </Form.Item>
                                        </div>

                                        <span>(UTC-0)</span>
                                      </div>

                                      <div className={styles.setupRepeatButtonAndSpanWrapper}>
                                        <Button
                                          className={styles.setupRepeatButton}
                                          onClick={() => updateOldValues(setShowSetupRepeatModal, index, true)}
                                        >
                                          Setup Repeat
                                        </Button>

                                        <span>{schedulerRepeatMessages[index] ?? repeatMessages.doesNotRepeat}</span>

                                        <SetupRepeatModal
                                          form={form}
                                          initialRepeatEveryNumber={patternDetails?.[index]?.schedule_repeat_every}
                                          initialRepeatEveryPeriod={patternDetails?.[index]?.schedule_repeat_type}
                                          field={field}
                                          patternIndex={index}
                                          modalVisible={showSetupRepeatModal[index]}
                                          onClose={() => updateOldValues(setShowSetupRepeatModal, index, false)}
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        <div className={styles.notParrentColWrapper}>
                          <Form.List {...field} name={[field.name, 'patternSetupFormList']} shouldUpdate>
                            {(setupFields, { add: addSetupRow, remove }) => {
                              return (
                                <div className={styles.patternSetupWrapper} style={{ width: '100%' }}>
                                  {setupFields.map((setupField, setupIndex) => (
                                    <Row key={setupIndex} gutter={8} style={{ width: '100%', borderBottom: '1px solid #d9d9d9' }}>
                                      <Form.Item
                                        {...setupField}
                                        name={[setupField.name, 'patternSetupId']}
                                        fieldKey={[setupField.fieldKey, 'patternSetupId']}
                                        hidden
                                      >
                                        <Input />
                                      </Form.Item>

                                      <Col span={3}>
                                        <Form.Item
                                          {...setupField}
                                          name={[setupField.name, 'type']}
                                          fieldKey={[setupField.fieldKey, 'type']}
                                          rules={[{ required: true, message: 'Please select type!' }]}
                                          label="Type"
                                        >
                                          <SelectWithRefetching
                                            placeholder="Select Type"
                                            optionFilterProp="data-searchvalue"
                                            disabled={isDisabled(index, setupIndex, SetupTypes.json)}
                                            onChange={() => {
                                              setTimeout(() => {
                                                // revalidate fields that have error (antd doesn't clear error automatically)
                                                const errorFields = form
                                                  .getFieldsError()
                                                  .map((e) => {
                                                    return e.errors.length > 0 ? e.name : null;
                                                  })
                                                  .filter((n) => n !== null);
                                                form.validateFields(errorFields);
                                              }, 0);
                                            }}
                                            loading={allSetupTypesLoading}
                                            refetching={allSetupTypesRefetching}
                                          >
                                            {generateCampaignPatternTypeSelectOptions(allSetupTypes)}
                                          </SelectWithRefetching>
                                        </Form.Item>
                                      </Col>

                                      <Col
                                        span={9}
                                        xxl={setupsGpssRuleByParentPatternIndex?.[index]?.[setupIndex] ? 9 : 10}
                                        hidden={!selectedSetupTypes?.[index]?.[setupIndex]}
                                      >
                                        <Row gutter={8}>
                                          <Col
                                            span={
                                              selectedSetupTypes?.[index]?.[setupIndex] === SetupTypes.regular
                                                ? 12
                                                : selectedSetupTypes?.[index]?.[setupIndex] === SetupTypes.ddl ||
                                                  selectedSetupTypes?.[index]?.[setupIndex] === SetupTypes.json
                                                ? 24
                                                : 0
                                            }
                                            hidden={isDisabled(index, setupIndex, SetupTypes.external)}
                                          >
                                            <Form.Item
                                              {...setupField}
                                              name={[setupField.name, 'product']}
                                              fieldKey={[setupField.fieldKey, 'product']}
                                              rules={[
                                                {
                                                  required: !isDisabled(index, setupIndex, SetupTypes.external),
                                                  message: 'Please select product!'
                                                }
                                              ]}
                                              label="Product"
                                            >
                                              {/* Antd select instead of virtualized select so we can show values that are not in select options - good for clone */}
                                              <SelectWithRefetching
                                                placeholder="Select Product"
                                                disabled={isDisabled(index, setupIndex, SetupTypes.external)}
                                                showSearch
                                                allowClear
                                                onChange={() => {
                                                  form.setFields([
                                                    {
                                                      name: ['patternFormList', index, 'patternSetupFormList', setupIndex, 'landingPage'],
                                                      value: undefined
                                                    }
                                                  ]);
                                                }}
                                                loading={allProductsLoading}
                                                refetching={allProductsRefetching}
                                                optionFilterProp="data-searchvalue"
                                              >
                                                {generateSelectOptionsWithIdValues(allProducts)}
                                              </SelectWithRefetching>
                                            </Form.Item>
                                          </Col>

                                          <Col
                                            span={selectedSetupTypes?.[index]?.[setupIndex] === SetupTypes.regular ? 12 : 0}
                                            hidden={
                                              isDisabled(index, setupIndex, SetupTypes.ddl) ||
                                              isDisabled(index, setupIndex, SetupTypes.external) ||
                                              isDisabled(index, setupIndex, SetupTypes.json)
                                            }
                                          >
                                            <Form.Item
                                              {...setupField}
                                              name={[setupField.name, 'landingPage']}
                                              fieldKey={[setupField.fieldKey, 'landingPage']}
                                              rules={[
                                                {
                                                  required: !(
                                                    isDisabled(index, setupIndex, SetupTypes.ddl) ||
                                                    isDisabled(index, setupIndex, SetupTypes.external) ||
                                                    isDisabled(index, setupIndex, SetupTypes.json)
                                                  ),
                                                  message: 'Please select landing page!'
                                                }
                                              ]}
                                              valuePropName="selectValue"
                                              label="Landing page"
                                            >
                                              <PreviewLpSelect
                                                disabled={
                                                  isDisabled(index, setupIndex, SetupTypes.ddl) ||
                                                  isDisabled(index, setupIndex, SetupTypes.external) ||
                                                  isDisabled(index, setupIndex, SetupTypes.json)
                                                }
                                                loading={allLandingPagesLoading}
                                                options={landingPagesService.generateOptions(
                                                  landingPagesSelectDropdown[index] && landingPagesSelectDropdown[index][setupIndex]
                                                    ? landingPagesSelectDropdown[index][setupIndex]
                                                    : allLandingPages
                                                )}
                                                renderOptionComponent={(option) => <LpPreview option={option} />}
                                              />
                                            </Form.Item>
                                          </Col>

                                          <Col
                                            span={selectedSetupTypes?.[index]?.[setupIndex] === SetupTypes.external ? 24 : 0}
                                            hidden={
                                              isDisabled(index, setupIndex, SetupTypes.regular) ||
                                              isDisabled(index, setupIndex, SetupTypes.ddl) ||
                                              isDisabled(index, setupIndex, SetupTypes.json)
                                            }
                                          >
                                            <Form.Item
                                              {...setupField}
                                              name={[setupField.name, 'externalUrl']}
                                              fieldKey={[setupField.fieldKey, 'externalUrl']}
                                              rules={[
                                                {
                                                  required: !(
                                                    isDisabled(index, setupIndex, SetupTypes.regular) ||
                                                    isDisabled(index, setupIndex, SetupTypes.ddl) ||
                                                    isDisabled(index, setupIndex, SetupTypes.json)
                                                  ),
                                                  message: 'Please select external URL!'
                                                }
                                              ]}
                                              label="External URL"
                                            >
                                              <SelectWithRefetching
                                                placeholder="Select External URL"
                                                optionFilterProp="data-searchvalue"
                                                showSearch
                                                allowClear
                                                disabled={
                                                  isDisabled(index, setupIndex, SetupTypes.regular) ||
                                                  isDisabled(index, setupIndex, SetupTypes.ddl) ||
                                                  isDisabled(index, setupIndex, SetupTypes.json)
                                                }
                                                loading={allExternalURLsLoading}
                                                refetching={allExternalURLsRefetching}
                                              >
                                                {getSelectOptionsWithIdValues(allExternalURLs)}
                                              </SelectWithRefetching>
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col span={8} hidden={!selectedSetupTypes?.[index]?.[setupIndex]}>
                                        <Row gutter={8}>
                                          {!isListForm && (
                                            <Col span={3}>
                                              <Form.Item
                                                {...setupField}
                                                name={[setupField.name, 'dynamicPixelOptimisation']}
                                                fieldKey={[setupField.fieldKey, 'dynamicPixelOptimisation']}
                                                valuePropName="checked"
                                                label="A.R."
                                                tooltip="Automation rules"
                                              >
                                                <Checkbox />
                                              </Form.Item>
                                            </Col>
                                          )}

                                          {dynamicPixelOptimisationPerSetup?.[index]?.[setupIndex] && !isListForm ? (
                                            <>
                                              <Col span={15}>
                                                <span className={styles.label} style={{ marginLeft: '20%' }}>
                                                  Automation rules
                                                </span>
                                                <AutomationRulesTargetingSection
                                                  visible={dynamicPixelOptimisationPerSetup?.[index]?.[setupIndex]}
                                                  field={setupField}
                                                  form={form}
                                                  isEdit={!!patternDetails}
                                                  pathName={['patternFormList', index, 'patternSetupFormList', setupIndex]}
                                                />
                                              </Col>

                                              <Col span={6}>
                                                <Form.Item
                                                  {...setupField}
                                                  name={[setupField.name, 's2s']}
                                                  fieldKey={[setupField.fieldKey, 's2s']}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: 'Please enter S2S!'
                                                    }
                                                  ]}
                                                  label="S2S"
                                                >
                                                  <InputNumber
                                                    style={{ width: '100%' }}
                                                    min={0}
                                                    max={100}
                                                    formatter={(value) => `${value}%`}
                                                    step={1}
                                                    parser={(value) => value.replace('%', '')}
                                                  />
                                                </Form.Item>
                                              </Col>
                                            </>
                                          ) : (
                                            <>
                                              <Col span={4}>
                                                <Form.Item
                                                  {...setupField}
                                                  name={[setupField.name, 'optimiseBySource']}
                                                  fieldKey={[setupField.fieldKey, 'optimiseBySource']}
                                                  valuePropName="checked"
                                                  label="O.B.S."
                                                  tooltip="Optimize by source"
                                                >
                                                  <Checkbox />
                                                </Form.Item>
                                              </Col>
                                              <Col span={17}>
                                                <Form.Item
                                                  {...setupField}
                                                  name={[setupField.name, 's2sExtended']}
                                                  fieldKey={[setupField.fieldKey, 's2sExtended']}
                                                >
                                                  <CustomPixelInput />
                                                </Form.Item>
                                              </Col>
                                            </>
                                          )}
                                        </Row>
                                      </Col>

                                      <Col span={2} hidden={!selectedSetupTypes?.[index]?.[setupIndex]} style={{ overflow: 'auto' }}>
                                        <Form.Item
                                          {...setupField}
                                          name={[setupField.name, 'percentage']}
                                          fieldKey={[setupField.fieldKey, 'percentage']}
                                          rules={[{ required: true, message: 'Please enter percentage!' }]}
                                          label="Percentage"
                                          style={{ minWidth: '70px' }}
                                        >
                                          <InputNumber
                                            style={{ width: '100%' }}
                                            min={0}
                                            max={100}
                                            formatter={(value) => `${value}%`}
                                            parser={(value) => value.replace('%', '')}
                                          />
                                        </Form.Item>
                                      </Col>

                                      {setupsGpssRuleByParentPatternIndex?.[index]?.[setupIndex] ? (
                                        <Col span={2}>
                                          <div
                                            className={`${
                                              Number(
                                                form.getFieldValue([
                                                  'patternFormList',
                                                  index,
                                                  'patternSetupFormList',
                                                  setupIndex,
                                                  'gpssRule'
                                                ])
                                              )
                                                ? styles.gpssRuleIdInputWrapper
                                                : ''
                                            }`}
                                          >
                                            <Link
                                              to={`/gpss?search=${form.getFieldValue([
                                                'patternFormList',
                                                index,
                                                'patternSetupFormList',
                                                setupIndex,
                                                'gpssRule'
                                              ])}`}
                                              target="_blank"
                                            >
                                              <Form.Item
                                                {...setupField}
                                                name={[setupField.name, 'gpssRule']}
                                                fieldKey={[setupField.fieldKey, 'gpssRule']}
                                                label="GPSS rule"
                                              >
                                                <Input readOnly />
                                              </Form.Item>
                                            </Link>
                                          </div>
                                        </Col>
                                      ) : (
                                        <Col span={2} xxl={1} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                          <Row gutter={8}>
                                            <Col span={12}>
                                              <Popover content="Clone" trigger="hover">
                                                <div onClick={() => patternSetupClone(index, setupIndex, addSetupRow, setupFields.length)}>
                                                  <img src="/images/copy.png" alt="Clone" width="20px" />
                                                </div>
                                              </Popover>
                                            </Col>

                                            <Col span={12}>
                                              <Popover content="Delete" trigger="hover">
                                                <div
                                                  onClick={() => {
                                                    Modal.confirm({
                                                      maskClosable: true,
                                                      title: 'Are you sure you want to remove pattern rule?',
                                                      okText: 'Yes',
                                                      okType: 'danger',
                                                      cancelText: 'No',
                                                      onOk: () => remove(setupField.name),
                                                      onCancel() {}
                                                    });
                                                  }}
                                                >
                                                  <img src="/images/delete_icon.png" alt="Delete" width="21px" />
                                                </div>
                                              </Popover>
                                            </Col>
                                          </Row>
                                        </Col>
                                      )}
                                    </Row>
                                  ))}

                                  <div className={styles.targetingAddDynamicFields + ' ' + styles.patternSetupRowBtnsWrapper}>
                                    <div className={styles.addPatternSetupRowWrapper}>
                                      <Form.Item>
                                        <Button
                                          ghost
                                          type="primary"
                                          onClick={() => {
                                            addSetupRow();
                                            form.setFields([
                                              {
                                                name: ['patternFormList', index, 'patternSetupFormList', setupFields.length, 'percentage'],
                                                value: setupFields.length === 0 ? 100 : 0
                                              },
                                              {
                                                name: ['patternFormList', index, 'patternSetupFormList', setupFields.length, 's2sExtended'],
                                                value: DEFAULT_S2S_ADVANCED_VALUE
                                              },
                                              {
                                                name: ['patternFormList', index, 'patternSetupFormList', setupFields.length, 's2s'],
                                                value: 100
                                              },
                                              {
                                                name: [
                                                  'patternFormList',
                                                  index,
                                                  'patternSetupFormList',
                                                  setupFields.length,
                                                  'automationRules'
                                                ],
                                                value: [DEFAULT_AUTOMATION_RULES_VALUE]
                                              }
                                            ]);
                                          }}
                                          block
                                        >
                                          <PlusOutlined /> Add Pattern Setup Row
                                        </Button>
                                      </Form.Item>
                                    </div>

                                    <div className={styles.evenlyDistributeAndCloneWrapper}>
                                      <Button
                                        className={styles.evenlyDistributeBtn}
                                        type="primary"
                                        onClick={() => evenlyDistributePercentage(index)}
                                      >
                                        Evenly Distribute %
                                      </Button>

                                      <Button
                                        className={styles.clonePatternBtn}
                                        type="primary"
                                        onClick={() => {
                                          patternClone(index, addPattern);
                                          activateOrderChangeTrigger();
                                        }}
                                      >
                                        Clone Pattern
                                      </Button>
                                    </div>
                                  </div>

                                  {patternsTotalPercentage[index] !== undefined && patternsTotalPercentage[index] !== 100 ? (
                                    <div className={styles.percentWarning}>
                                      <ExclamationCircleFilled />
                                      Landing Page Percentage is {patternsTotalPercentage[index]}%, it must be 100% in sum.
                                    </div>
                                  ) : null}
                                </div>
                              );
                            }}
                          </Form.List>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div
                    className={styles.targetingAddDynamicFields + ' ' + styles.targetingAddPattern + ' ' + styles.formRemoveBottomMargin}
                  >
                    <Form.Item>
                      <Button
                        ghost
                        type="primary"
                        onClick={() => {
                          addPattern({ origin: null });
                          activateOrderChangeTrigger();
                          form.setFields(defaultModalFields(fields.length));
                        }}
                        block
                      >
                        <PlusOutlined /> Add New Pattern
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              );
            }}
          </Form.List>
        </div>
      </div>
    </div>
  );
};
