import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Header } from './components/Header';
import { PurchaseDetails } from './components/PurchaseDetails';
import { SortDirection } from '../../services/statistic';
import { Table } from './components/Table';
import { useFetchDomainPurchases } from '../../utils/hooks/queries';

export const DomainPurchase = (props) => {
  const history = useHistory();
  const urlId = props.match.params.id;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [filters, setFilters] = useState({});
  const [sorting, setSorting] = useState([{ key: 'date_created', value: SortDirection.Descending }]);

  const [shouldRefetch, setShouldRefetch] = useState(false);
  const shouldRefetchRef = useRef(shouldRefetch);

  const {
    isFetching,
    data: { results: domainPurchases, count: totalItems },
    refetch
  } = useFetchDomainPurchases({ currentPage, pageSize, filters, sorting });

  useEffect(() => {
    if (!isFetching) {
      const newShouldRefetch = domainPurchases.some((el) => !(el.status === 'DONE' || el.status === 'FAILED'));
      setShouldRefetch(newShouldRefetch);
    }
  }, [domainPurchases, isFetching]);

  useEffect(() => {
    setShouldRefetch(false);
  }, [currentPage, pageSize, filters, sorting]);

  useEffect(() => {
    let timeoutId = null;
    shouldRefetchRef.current = shouldRefetch && !urlId;
    const refetchPurchases = async () => {
      if (shouldRefetchRef.current) {
        // we must use a ref because otherwise the value of shouldRefetch would be captured by refetchPurchases closure and never change
        await refetch();
      }
      if (shouldRefetchRef.current) {
        // we need to check again because refetch is async so shouldRefetchRef could have changed in the meantime
        timeoutId = setTimeout(() => {
          refetchPurchases();
        }, 1000);
      }
    };

    refetchPurchases();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [shouldRefetch, refetch, urlId]);

  const openDetailsPage = (requestId) => {
    history.push(`/domains/domain-purchase/${requestId}`);
  };

  return (
    <div>
      {urlId ? (
        <PurchaseDetails requestId={urlId} />
      ) : (
        <>
          <h1>Domain Purchase</h1>
          <Header
            onPurchase={(requestId) => {
              openDetailsPage(requestId);
            }}
          />
          <Table
            loading={isFetching && !shouldRefetch}
            refreshing={isFetching && shouldRefetch}
            domainPurchases={domainPurchases}
            totalItems={totalItems}
            currentPage={currentPage}
            pageSize={pageSize}
            sorting={sorting}
            onPaginationChange={setCurrentPage}
            onSortingClick={setSorting}
          />
        </>
      )}
    </div>
  );
};
