import React from 'react';

import styles from './TagListWithEditModal.module.css';
import { EditOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

export const TagListWithEditModal = ({ tagLabels, onModalOpen, style = null }) => (
  <div className={styles.tagsContainerOuterWrapper} style={style}>
    <div className={styles.tagsContainerInnerWrapper}>
      <div className={styles.tagsContainer}>{tagLabels.map((label, index) => (label ? <Tag key={index}>{label}</Tag> : null))}</div>
      {onModalOpen && (
        <div className={styles.editWrapper} onClick={() => onModalOpen(true)}>
          <EditOutlined />
        </div>
      )}
    </div>
  </div>
);
