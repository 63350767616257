import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';

import { SearchBar } from '../../../components/SearchBar';
import { AddOrEditFacebookPixel } from './AddOrEditFacebookPixel';
import { auth } from '../../../services/auth';

export const Header = ({ searchValue, onSearch, onAdd }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleOpenModal = () => setModalVisible(true);
  const handleCloseModal = () => setModalVisible(false);

  const hasAddPermission = auth.hasPermission('add_facebookpixel');

  return (
    <>
      <Row>
        <h1>Facebook Pixels</h1>
      </Row>

      <Row gutter={32}>
        <Col>
          <SearchBar
            value={searchValue}
            width={250}
            onChange={(e) => {
              onSearch(e.target.value);
            }}
          />
        </Col>

        <Col>
          {hasAddPermission && (
            <Button type="primary" icon={<PlusOutlined />} onClick={handleOpenModal}>
              Add New Facebook Pixel
            </Button>
          )}
        </Col>
      </Row>

      <AddOrEditFacebookPixel
        modalVisible={modalVisible}
        onClose={handleCloseModal}
        onAdd={() => {
          onAdd();
          handleCloseModal();
        }}
      />
    </>
  );
};
