import React, { useState } from 'react';
import { Button, Col, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { PurchaseModal } from './PurchaseModal';

export const Header = ({ onPurchase }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleOpenModal = () => setModalVisible(true);
  const handleCloseModal = () => setModalVisible(false);

  return (
    <>
      <Row gutter={32}>
        <Col span={6}>
          <Button type="primary" icon={<PlusOutlined />} onClick={handleOpenModal}>
            Purchase Domains
          </Button>
        </Col>
      </Row>

      <PurchaseModal
        modalVisible={modalVisible}
        onCancel={handleCloseModal}
        onPurchase={(requestId) => {
          onPurchase(requestId);
          handleCloseModal();
        }}
      />
    </>
  );
};
