import { handleOrdering } from '../utils/functions';
import { getOffsetLimitFromPagination, getQueryString } from '../utils/query';
import { callApi } from './api';

export const IdentityStatus = {
  Active: 'Active',
  Paused: 'Paused'
};

export const IdentityType = {
  Fixed: 'Fixed',
  Replacement: 'Replacement'
};

export const IdentityPurpose = {
  WebPush: 'WebPush',
  Custom: 'Custom',
  AzureWebPush: 'AzureWebPush'
};

class IdentityService {
  async getAll({ fields = 'id,name' } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/identities/${query}` });
  }

  async getPaged({ currentPage, pageSize, filters, searchValue, sorting, cancelToken }) {
    const ordering = handleOrdering(sorting);

    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      type: filters.type,
      status: filters.status,
      purpose: filters.purpose,
      search: searchValue,
      ordering: ordering && ordering.join(',')
    });
    return await callApi({
      url: `/api/identities/${query}`,
      cancelToken
    });
  }

  async getPagedDomains({ id, currentPage, pageSize, cancelToken }) {
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize })
    });
    return await callApi({
      url: `/api/identities/${id}/domains/${query}`,
      cancelToken
    });
  }

  async getAvailableDomains(identityId) {
    return await callApi({
      url: `/api/domains/get_available_domains/?identity_id=${identityId}`
    });
  }

  async create(data) {
    return await callApi({ url: '/api/identity/', method: 'POST', data });
  }

  async edit(id, data) {
    return await callApi({ url: `/api/identity/${id}/`, method: 'PUT', data });
  }

  async removeDomain(domainId) {
    return await callApi({
      method: 'POST',
      url: `/api/domains/${domainId}/remove_from_identity/`
    });
  }

  async addDomain(identityId, domainId) {
    return await callApi({
      method: 'PATCH',
      url: `/api/domains/${domainId}/`,
      data: { identity: identityId }
    });
  }

  async setAutoRenew(domainId, enable) {
    return await callApi({
      method: 'PUT',
      url: `/api/domains/${domainId}/set_auto_renew/`,
      data: { auto_renew: enable }
    });
  }

  async getRenewalReadyDomains({ currentPage, pageSize, cancelToken }) {
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize })
    });
    return await callApi({
      url: `/api/domains/get_renewal_ready_domains/${query}`,
      cancelToken
    });
  }

  generateOptions = (arr) => {
    return arr.map((option) => {
      return {
        label: option.name,
        value: option.id
      };
    });
  };
}

export const identityService = new IdentityService();
