import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MenuOutlined, DeleteOutlined } from '@ant-design/icons';
import styles from './PresetConfigModal.module.css';
import { Button, Checkbox, Col, Dropdown, Input, InputNumber, Menu, Modal, Row, Select, Table, Tag } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { allBasicMetrics, ChannelColumnGroups, ChannelColumnType } from '../utils/columns';
import { openErrorNotification } from '../../../utils/notifications';
import { generateRandomString } from '../../../utils/functions';

const ColumnTypeTagColor = {
  [ChannelColumnType.Metric]: 'blue',
  [ChannelColumnType.Dimension]: 'orange',
  [ChannelColumnType.DerivedMetric]: 'green',
  [ChannelColumnType.Custom]: 'lightgrey'
};

const ColumnTypeTagText = {
  [ChannelColumnType.Metric]: 'Basic metric',
  [ChannelColumnType.Dimension]: 'Dimension',
  [ChannelColumnType.DerivedMetric]: 'Derived metric',
  [ChannelColumnType.Custom]: 'Custom'
};

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999'
    }}
  />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const withInput = (WrappedComponent) => {
  const InputTableCellComponent = ({ value, onChange, ...otherProps }) => {
    const [tempValue, setTempValue] = useState(value);

    const handleChange = (e) => {
      setTempValue(e?.target ? e.target.value : e);
    };

    useEffect(() => {
      setTempValue(value);
    }, [value]);

    // We'll only update the external data when the input is blurred
    const handleBlur = () => {
      onChange(tempValue);
    };

    return <WrappedComponent value={tempValue} onChange={handleChange} onBlur={handleBlur} {...otherProps} />;
  };

  return InputTableCellComponent;
};

const InputTableCell = withInput(Input);
const InputNumberTableCell = withInput(InputNumber);

const FormulaInput = ({ value, onChange, ...otherProps }) => {
  const [tempValue, setTempValue] = useState(value);

  const inputRef = useRef(null);

  const handleChange = (e) => {
    setTempValue(e?.target ? e.target.value : e);
  };

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  // We'll only update the external data when the input is blurred
  const handleBlur = () => {
    onChange(tempValue);
  };

  const insertMacro = (text) => {
    const input = inputRef.current.input;
    const cursorPosition = input.selectionStart;
    const newText = tempValue.slice(0, cursorPosition) + text + tempValue.slice(cursorPosition);

    setTempValue(newText);

    // Set the cursor to the end of the inserted text
    setTimeout(() => {
      input.focus();
      input.selectionStart = input.selectionEnd = cursorPosition + text.length;
    }, 0);
  };

  const overlay = (
    <Menu>
      {allBasicMetrics.map((c) => (
        <Menu.Item key={c.value} onClick={() => insertMacro(c.value)}>
          {c.text}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Input
      value={tempValue}
      onChange={handleChange}
      onBlur={handleBlur}
      addonAfter={
        <Dropdown overlay={overlay} trigger={['click']} placement="bottomRight" overlayClassName={styles.macroDropdown}>
          <span style={{ cursor: 'pointer' }}>{'{ }'}</span>
        </Dropdown>
      }
      {...otherProps}
      ref={inputRef}
    />
  );
};

export const PresetConfigModal = ({ visible, columnsData, onClose, onAddNewColumn, onDeleteColumn, onSortEnd, onColumnsDataChange }) => {
  const [showOnlyIncluded, setShowOnlyIncluded] = useState(false);

  const columns = [
    {
      title: 'Included',
      dataIndex: 'selected',
      width: 30,
      render: (selected, { index }) => (
        <Row justify="center">
          <Col>
            <Checkbox checked={!!selected} onChange={(e) => onColumnsDataChange(index, 'selected', e.target.checked)} />
          </Col>
        </Row>
      )
    },
    {
      title: 'Order',
      key: 'column_number',
      width: 30,
      className: 'drag-visible',
      render: (_, { index }) => {
        return (
          <Row justify="center">
            <Col>
              <DragHandle />
              <span style={{ marginLeft: 10 }}>{index + 1}</span>
            </Col>
          </Row>
        );
      }
    },
    {
      title: 'Column name',
      key: 'text',
      render: (_, record) => {
        const fieldName = record.type === ChannelColumnType.Custom ? 'newText' : 'text';
        return <InputTableCell value={record[fieldName]} disabled={true} />;
      }
    },
    {
      title: 'New column name',
      key: 'newText',
      render: (_, { type, newText, index }) => {
        const disabled = type === ChannelColumnType.Dimension;
        return <InputTableCell value={newText} disabled={disabled} onChange={(value) => onColumnsDataChange(index, 'newText', value)} />;
      }
    },
    {
      title: 'Column type',
      dataIndex: 'type',
      render: (type) => {
        return <Tag color={ColumnTypeTagColor[type]}>{ColumnTypeTagText[type]}</Tag>;
      }
    },
    {
      title: 'Column formula',
      key: 'formula',
      render: (_, { type, formula, index }) => {
        return type !== ChannelColumnType.Custom ? (
          <InputTableCell value={formula} disabled={true} />
        ) : (
          <FormulaInput value={formula} onChange={(value) => onColumnsDataChange(index, 'formula', value)} />
        );
      }
    },
    {
      title: 'Output type',
      key: 'output',
      render: (_, { type, output, index }) => {
        const disabled = type !== ChannelColumnType.Custom;
        return (
          <Select
            style={{ width: 120 }}
            options={[
              {
                value: 'percent',
                label: 'Percentage'
              },
              {
                value: 'decimal',
                label: 'Decimal'
              },
              {
                value: 'currency',
                label: 'Currency'
              }
            ]}
            value={output}
            disabled={disabled}
            onChange={(value) => onColumnsDataChange(index, 'output', value)}
          />
        );
      }
    },
    {
      title: 'Decimals',
      key: 'decimal_places',
      render: (_, { type, decimal_places, index }) => {
        const disabled = type !== ChannelColumnType.Custom;
        return (
          <InputNumberTableCell
            value={decimal_places}
            disabled={disabled}
            onChange={(value) => onColumnsDataChange(index, 'decimal_places', value)}
          />
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, { type, formula, index }) => {
        const disabled = type !== ChannelColumnType.Custom;

        return (
          <div className={styles.actionBtns}>
            <Button icon={<DeleteOutlined />} disabled={disabled} onClick={() => onDeleteColumn(index)} />
          </div>
        );
      }
    }
  ];

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(columnsData.slice(), oldIndex, newIndex).filter((el) => !!el);
      onSortEnd(newData);
    }
  };

  const handleSortEndRef = useRef(handleSortEnd);
  useEffect(() => {
    handleSortEndRef.current = handleSortEnd;
  });

  const columnsDataRef = useRef(columnsData);
  useEffect(() => {
    columnsDataRef.current = columnsData;
  });

  const DraggableContainer = useCallback(
    (props) => <SortableBody useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={handleSortEndRef.current} {...props} />,
    []
  );

  const DraggableBodyRow = useCallback(({ className, style, ...restProps }) => {
    const index = columnsDataRef.current.findIndex((x) => x.value === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  }, []);

  const handleAdd = () => {
    const newData = {
      value: generateRandomString(10),
      selected: true,
      text: '',
      newText: '',
      type: ChannelColumnType.Custom,
      group: ChannelColumnGroups.Custom,
      formula: '',
      output: 'decimal',
      decimal_places: 2
    };
    onAddNewColumn([...columnsData, newData]);
  };

  const getInvalidCustomColumnsFields = () => {
    const invalidFieldsByRowNumber = {};
    columnsData.forEach((column, index) => {
      const { type, newText, formula } = column;
      if (type === ChannelColumnType.Custom) {
        let invalidFields = [];
        if (!newText) {
          invalidFields = ['newText'];
        }
        if (!formula) {
          invalidFields = [...invalidFields, 'formula'];
        }
        if (invalidFields.length) {
          invalidFieldsByRowNumber[index + 1] = invalidFields;
        }
      }
    });
    return invalidFieldsByRowNumber;
  };

  const handleClose = () => {
    const invalidFields = getInvalidCustomColumnsFields();
    const invalidFieldsKeys = Object.keys(invalidFields);
    if (invalidFieldsKeys.length) {
      let errorMessage = [];
      invalidFieldsKeys.forEach((fieldKey) => {
        errorMessage.push(
          <>
            <div>
              {fieldKey}: {invalidFields[fieldKey].join(', ')}
            </div>
          </>
        );
      });

      openErrorNotification({
        message: (
          <div>
            Validation failed:
            <br /> {errorMessage}
          </div>
        )
      });
      return;
    }
    onClose();
  };

  const columnDataWithIndex = columnsData.map((c, i) => ({ ...c, index: i }));
  const visibleColumnData = showOnlyIncluded ? columnDataWithIndex.filter((c) => c.selected) : columnDataWithIndex;

  return (
    <Modal
      title="Column Configuration"
      visible={visible}
      onCancel={handleClose}
      width={'100%'}
      className={styles}
      footer={
        <div>
          <Button onClick={handleClose}>Close</Button>
        </div>
      }
    >
      <Checkbox checked={showOnlyIncluded} onChange={(e) => setShowOnlyIncluded(e.target.checked)} style={{ marginBottom: '10px' }}>
        Show only included columns
      </Checkbox>
      <Table
        pagination={false}
        dataSource={visibleColumnData}
        columns={columns}
        rowKey="value"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow
          }
        }}
      />
      <Button onClick={handleAdd} style={{ marginBottom: 16 }}>
        Add a row
      </Button>
    </Modal>
  );
};
