import axiosStatic from 'axios';

import { API_BASE_URL, IAM_URL } from '../config';
import { openErrorNotification } from '../utils/notifications';

const axiosInstance = axiosStatic.create({
  baseURL: API_BASE_URL,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  withCredentials: true // because of IAM cross-origin call
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = `${IAM_URL}/login?service_redirect_url=${window.location.origin}`;
    } else if (error.response && error.response.status === 403) {
      openErrorNotification({ message: 'You do not have permission to perform this action.' });
    }
    // TODO: some error message if something else fails (not 401 or 403) ?
    return Promise.reject(error);
  }
);

// TODO: add more options
export const callApi = ({ url, method, data, cancelToken, headers, responseType, baseURL }) =>
  axiosInstance({ url, method: method || 'GET', data, signal: cancelToken, headers, responseType, baseURL }).then(
    (response) => response.data
  );

export const getCancelToken = () => new AbortController();

export const isCancelled = (error) => axiosStatic.isCancel(error);
