import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, Form, Input, Button, Select, Radio, Divider, Checkbox } from 'antd';

import { confirmClose } from '../../../utils/confirmClose';
import { openSuccessNotification } from '../../../utils/notifications';
import { domainPurchaseService } from '../../../services/domain-purchase';
import { showApiErrors } from '../../../utils/showApiErrors';
import { testsService } from '../../../services/tests';
import { accountsService } from '../../../services/accounts';
import { IdentityPurpose, identityService, IdentityType } from '../../../services/identity';
import { getAccountsSelectOptions } from '../../lists/utils/options';
import { generateSelectOptionsWithIdValues } from '../../../utils/options';
import { useEffectTrigger, useForm } from '../../../utils/hooks';

const { TextArea } = Input;

const validDomainRegex = /^((?=[a-z0-9-]{1,63}\.)[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}$/;

export const PurchaseModal = ({ modalVisible, onCancel, onPurchase }) => {
  const [formChangedTrigger, activateFormChangedTrigger] = useEffectTrigger();
  const [form] = useForm(activateFormChangedTrigger);
  const [wasFormChanged, setWasFormChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [createNewIdentity, setCreateNewIdentity] = useState(true);

  const [identitiesLoading, setIdentitiesLoading] = useState(false);
  const [allIdentities, setAllIdentities] = useState([]);

  const [testsLoading, setTestsLoading] = useState(false);
  const [allTests, setAllTests] = useState([]);

  const [allAccountsLoading, setAllAccountsLoading] = useState(false);
  const [allAccounts, setAllAccounts] = useState([]);

  const [separateIdentities, setSeparateIdentities] = useState(false);
  const [selectedPurpose, setSelectedPurpose] = useState();

  const shouldShowOcambaId = selectedPurpose === IdentityPurpose.WebPush || selectedPurpose === IdentityPurpose.AzureWebPush;

  useEffect(() => {
    if (modalVisible) {
      form.resetFields();
      setWasFormChanged(false);
    }
  }, [form, modalVisible]);

  useEffect(() => {
    const { separate_identities, purpose, identity } = form.getFieldsValue(true);
    setSeparateIdentities(!!separate_identities);

    if (identity) {
      setSelectedPurpose(allIdentities.find((i) => i.id === identity)?.purpose);
    } else {
      setSelectedPurpose(purpose);
    }
  }, [form, formChangedTrigger, allIdentities]);

  useEffect(() => {
    const getIdentities = async () => {
      setIdentitiesLoading(true);
      try {
        const identities = await identityService.getAll({ fields: 'id,name,purpose' });
        setAllIdentities(identities);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setIdentitiesLoading(false);
      }
    };

    const getTests = async () => {
      setTestsLoading(true);
      try {
        const tests = await testsService.getAll();
        setAllTests(tests);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setTestsLoading(false);
      }
    };

    const getAccounts = async () => {
      setAllAccountsLoading(true);
      try {
        const accounts = await accountsService.getAll();
        setAllAccounts(accounts);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllAccountsLoading(false);
      }
    };

    getIdentities();
    getTests();
    getAccounts();
  }, []);

  const handleClose = () => {
    wasFormChanged ? confirmClose(onCancel) : onCancel();
  };

  const handleSubmit = async (values) => {
    try {
      setSubmitting(true);
      const data = { ...values };
      data.domains = data.domains.split('\n');
      const { request_id: requestId } = await domainPurchaseService.purchaseDomains(data);
      openSuccessNotification({ message: 'Successfully scheduled domain purchase' });
      onPurchase(requestId);
    } catch (e) {
      showApiErrors(e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title="Purchase Domains"
      visible={modalVisible}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={submitting} onClick={() => form.submit()}>
          Purchase
        </Button>
      ]}
      width={800}
    >
      <Radio.Group
        onChange={(e) => {
          setCreateNewIdentity(e.target.value);
          form.resetFields();
        }}
        value={createNewIdentity}
        style={{ width: '100%' }}
      >
        <Row gutter={24}>
          <Col offset={3} span={10}>
            <Radio value={true}>Create new identity/identities</Radio>
          </Col>

          <Col span={10}>
            <Radio value={false}>Add to existing identity</Radio>
          </Col>
        </Row>
      </Radio.Group>
      <Divider />
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        onFinishFailed={({ errorFields }) => {
          form.scrollToField(errorFields[0].name);
        }}
        onValuesChange={() => {
          setWasFormChanged(true);
          activateFormChangedTrigger();
        }}
      >
        <Row gutter={24}>
          <Col span={12}>
            {createNewIdentity ? (
              <>
                <Row gutter={32}>
                  <Col span={24}>
                    <Form.Item label="Purpose" name="purpose" rules={[{ required: true, message: 'Please select purpose!' }]}>
                      <Select placeholder="Select purpose" showSearch style={{ width: '100%' }}>
                        <Select.Option value={IdentityPurpose.WebPush}>WebPush</Select.Option>
                        <Select.Option value={IdentityPurpose.Custom}>Custom</Select.Option>
                        <Select.Option value={IdentityPurpose.AzureWebPush}>Azure WebPush</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name="separate_identities" valuePropName="checked">
                      <Checkbox>Separate Identities</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {!separateIdentities && (
                      <Form.Item
                        label="Identity Name"
                        name="identity_name"
                        rules={[{ required: true, message: 'Please input identity name!' }]}
                      >
                        <Input placeholder="Identity Name" />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item label="Type" name="type" rules={[{ required: true, message: 'Please select type!' }]}>
                      <Select placeholder="Select type" showSearch style={{ width: '100%' }}>
                        <Select.Option value={IdentityType.Fixed}>Fixed</Select.Option>
                        <Select.Option value={IdentityType.Replacement}>Replacement</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item label="Tests" name="tests">
                      <Select
                        placeholder="Select tests"
                        optionFilterProp="data-searchvalue"
                        showSearch
                        allowClear
                        loading={testsLoading}
                        style={{ width: '100%' }}
                        mode="multiple"
                      >
                        {generateSelectOptionsWithIdValues(allTests)}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item label="Notify Users" name="notify_users">
                      <Select
                        mode="multiple"
                        placeholder="Select users"
                        allowClear
                        optionFilterProp="data-searchvalue"
                        loading={allAccountsLoading}
                        style={{ width: '100%' }}
                      >
                        {getAccountsSelectOptions(allAccounts)}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : (
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="identity"
                    label="Identity"
                    rules={[
                      {
                        required: true,
                        message: 'Please select an identity!'
                      }
                    ]}
                  >
                    <Select placeholder="Identity" optionFilterProp="data-searchvalue" showSearch allowClear loading={identitiesLoading}>
                      {generateSelectOptionsWithIdValues(allIdentities)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row>
              <Col span={24}>
                {shouldShowOcambaId && (
                  <Form.Item label="Ocamba Tag Id" name="ocamba_tag_id">
                    <Input placeholder="Ocamba Tag Id (leave blank for autogenerate)" />
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Form.Item
              name="domains"
              label="Domains"
              rules={[
                { required: true, message: 'Please input domains!' },
                {
                  validator: async (_, value) => {
                    if (value) {
                      const domains = value.split('\n');
                      for (let domain of domains) {
                        if (!validDomainRegex.test(domain)) {
                          throw new Error(`${domain} is not a valid domain name!`);
                        }
                      }
                    }
                  }
                }
              ]}
            >
              <TextArea placeholder="Input domains (one per line)" style={{ minHeight: '400px' }}></TextArea>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
