import { Button, Col, Form, Input, Modal, Row, Select, Spin, Switch } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { confirmClose } from '../../../utils/confirmClose';
import { getAccounts, useCustomQuery } from '../../../utils/hooks/queries';
import { withRefetching } from '../../../components/SelectWithSideLoader';
import { getAccountsSelectOptions } from '../../lists/utils/options';
import { statisticServices } from '../../../services/statistic';
import { showApiErrors } from '../../../utils/showApiErrors';
import { openSuccessNotification } from '../../../utils/notifications';
import { removeExtraFields } from '../utils/columns';

const SelectWithRefetching = withRefetching(Select);

export const SaveAsOrEditPresetModal = ({ id, visible, saveAsPresetData, saveAsPresetDataLoading, onSuccess, onCancel }) => {
  const [form] = Form.useForm();
  const [wasFormChanged, setWasFormChanged] = useState(false);
  const [savingPreset, setSavingPreset] = useState(false);
  const [presetData, setPresetData] = useState();
  const [presetDataLoading, setPresetDataLoading] = useState(false);

  const isEdit = !!id;

  const titleAndButtonText = isEdit ? 'Rename/Assign' : 'Save as...';
  const handleCancel = () => {
    wasFormChanged ? confirmClose(() => onCancel()) : onCancel();
  };

  const { isLoading: allAccountsLoading, isFetching: allAccountsRefetching, data: allAccounts } = useCustomQuery(getAccounts);

  const handleEdit = async (values) => {
    try {
      setSavingPreset(true);

      if (isEdit) {
        await statisticServices.editChannelStatsPreset(id, values);
        openSuccessNotification({ message: 'Successfully edited preset!' });
      } else {
        const newColumns = removeExtraFields(presetData.data.columns);
        const requestData = { ...presetData, ...values, data: { ...presetData.data, columns: newColumns } };
        await statisticServices.addChannelStatsPreset(requestData);
        openSuccessNotification({ message: 'Successfully saved preset!' });
      }
      onSuccess();
    } catch (e) {
      showApiErrors(e);
    } finally {
      setSavingPreset(false);
    }
  };

  const initialValues = useMemo(() => {
    if (!presetData) {
      return undefined;
    }

    return presetData;
  }, [presetData]);

  useEffect(() => {
    if (visible && initialValues) {
      // Doesn't work without setTimeout
      setTimeout(() => {
        form.resetFields();
      }, 0);
      setWasFormChanged(false);
    }
  }, [form, visible, initialValues]);

  useEffect(() => {
    if (saveAsPresetData) {
      setPresetData(saveAsPresetData);
      setPresetDataLoading(saveAsPresetDataLoading);
    }
  }, [saveAsPresetData, saveAsPresetDataLoading]);

  useEffect(() => {
    if (id && visible) {
      (async () => {
        try {
          setPresetDataLoading(true);
          const presetData = await statisticServices.getChannelStatsPresetById(id);
          setPresetData(presetData);
        } catch (e) {
          showApiErrors(e);
        } finally {
          setPresetDataLoading(false);
        }
      })();
    }
  }, [id, visible]);

  return (
    <Modal
      title={titleAndButtonText}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" loading={savingPreset} onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={savingPreset} onClick={() => form.submit()}>
          Submit
        </Button>
      ]}
    >
      {presetDataLoading ? (
        <Spin size="large" />
      ) : (
        <Form
          form={form}
          initialValues={initialValues}
          layout="vertical"
          onFinish={handleEdit}
          onValuesChange={() => setWasFormChanged(true)}
        >
          <Row>
            <Col span={24}>
              <Form.Item name="name" label="Preset name" rules={[{ required: true, message: 'Please enter preset name!' }]}>
                <Input placeholder="Enter preset name" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item name="assigned_users" label="Users">
                <SelectWithRefetching
                  mode="multiple"
                  allowClear
                  optionFilterProp="data-searchvalue"
                  loading={allAccountsLoading}
                  refetching={allAccountsRefetching}
                  placeholder="Assign users"
                >
                  {getAccountsSelectOptions(allAccounts)}
                </SelectWithRefetching>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item
                name="is_public"
                label="Set public"
                rules={[{ required: true, message: 'Please enter preset name!' }]}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};
