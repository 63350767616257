import React, { useEffect, useState } from 'react';
import { facebookPixelsService } from '../../services/facebook-pixels';
import { SortDirection } from '../../services/statistic';
import { useDebounce, useFetch } from '../../utils/hooks';

import { Header } from './components/Header';
import { Table } from './components/Table';

export const FacebookPixels = () => {
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [sorting, setSorting] = useState([{ key: 'created_at', value: SortDirection.Descending }]);

  const debouncedSearchValue = useDebounce(searchValue);

  const loadFacebookPixels = (cancelToken) =>
    facebookPixelsService.getPaged({ currentPage, pageSize, searchValue: debouncedSearchValue, cancelToken, sorting });

  const [
    {
      data: { results: facebookPixels, count: totalItems },
      isFetching
    },
    getFacebookPixels
  ] = useFetch(loadFacebookPixels);

  useEffect(() => {
    getFacebookPixels();
  }, [currentPage, debouncedSearchValue, getFacebookPixels, sorting]);

  useEffect(() => setCurrentPage(1), [debouncedSearchValue, sorting]);

  return (
    <div>
      <Header searchValue={searchValue} onSearch={setSearchValue} onAdd={getFacebookPixels} />

      <Table
        facebookPixels={facebookPixels}
        facebookPixelsLoading={isFetching}
        totalItems={totalItems}
        currentPage={currentPage}
        pageSize={pageSize}
        sorting={sorting}
        onPaginationChange={(page) => setCurrentPage(page)}
        onEdit={getFacebookPixels}
        onDelete={getFacebookPixels}
        onSortingClick={setSorting}
      />
    </div>
  );
};
