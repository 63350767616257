import { callApi } from './api';
import { getOffsetLimitFromPagination, getQueryString } from '../utils/query';
import { handleOrdering } from '../utils/functions';

class DomainPurchaseService {
  async getPaged({ currentPage, pageSize, cancelToken, filters, sorting }) {
    const ordering = handleOrdering(sorting);
    const query = getQueryString({
      ordering: ordering && ordering.join(','),
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      ...filters
    });
    return await callApi({ url: `/api/domains_integrations/get_purchases/${query}`, cancelToken });
  }

  async getDetails(requestId) {
    return await callApi({ url: `/api/domains_integrations/get_domains_by_request_id/?request_id=${requestId}` });
  }

  async purchaseDomains(data) {
    return await callApi({ url: '/api/domains_integrations/populate_integration/', method: 'POST', data });
  }
}

export const domainPurchaseService = new DomainPurchaseService();
