import React, { useEffect, useMemo, useState } from 'react';
import { Col, Modal, Row, Form, Input, Button, InputNumber } from 'antd';

import { DomainsInfoTable } from './DomainsInfoTable';
import { useFetch } from '../../../utils/hooks';
import { IdentityPurpose, identityService, IdentityType } from '../../../services/identity';
import { testsService } from '../../../services/tests';
import { Select } from '../../../components/Select';
import { confirmClose } from '../../../utils/confirmClose';
import { showApiErrors } from '../../../utils/showApiErrors';
import { openSuccessNotification } from '../../../utils/notifications';
import { accountsService } from '../../../services/accounts';
import { generateSelectOptionsWithIdValues } from '../../../utils/options';
import { getAccountsSelectOptions } from '../../lists/utils/options';

export const EditIdentityModal = ({ modalVisible, identity, onCancel, onEdit }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  const [form] = Form.useForm();
  const [wasFormChanged, setWasFormChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [testsLoading, setTestsLoading] = useState(false);
  const [allTests, setAllTests] = useState([]);

  const [allAccountsLoading, setAllAccountsLoading] = useState(false);
  const [allAccounts, setAllAccounts] = useState([]);

  const [isFixed, setIsFixed] = useState(false);

  const id = identity.id;

  const loadDomains = (cancelToken) => identityService.getPagedDomains({ id, currentPage, pageSize, cancelToken });

  const [
    {
      data: { results: domains, count: totalItems },
      isFetching
    },
    getDomains
  ] = useFetch(loadDomains);

  const initialValues = useMemo(() => {
    setIsFixed(identity.type === IdentityType.Fixed);
    return identity;
  }, [identity]);

  useEffect(() => {
    if (modalVisible) {
      getDomains();
    }
  }, [currentPage, getDomains, modalVisible, identity]);

  useEffect(() => {
    form.resetFields();
    setWasFormChanged(false);
    setCurrentPage(1);
  }, [form, initialValues, modalVisible]);

  useEffect(() => {
    if (isFixed) {
      form.setFieldsValue({ active_domains_num: 1, replacement_interval: 0 });
    }
  }, [form, isFixed]);

  useEffect(() => {
    const getTests = async () => {
      setTestsLoading(true);
      try {
        const tests = await testsService.getAll();
        setAllTests(tests);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setTestsLoading(false);
      }
    };

    const getAccounts = async () => {
      setAllAccountsLoading(true);
      try {
        const accounts = await accountsService.getAll();
        setAllAccounts(accounts);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllAccountsLoading(false);
      }
    };

    getTests();
    getAccounts();
  }, []);

  const handleClose = () => {
    wasFormChanged ? confirmClose(onCancel) : onCancel();
  };

  const handleSubmit = async (values) => {
    try {
      setSubmitting(true);
      await identityService.edit(id, values);
      openSuccessNotification({ message: 'Successfully edited identity' });
      onEdit();
    } catch (e) {
      showApiErrors(e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal title={`Edit Identity (${id})`} visible={modalVisible} onCancel={onCancel} width={1200} footer={null}>
      <Row gutter={24}>
        <Col span={10}>
          <Form
            form={form}
            initialValues={initialValues}
            onFinish={handleSubmit}
            layout="vertical"
            onFinishFailed={({ errorFields }) => {
              form.scrollToField(errorFields[0].name);
            }}
            onValuesChange={() => setWasFormChanged(true)}
          >
            <Row gutter={32}>
              <Col span={24}>
                <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input identity name!' }]}>
                  <Input placeholder="Identity Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24}>
                <Form.Item label="Purpose" name="purpose">
                  <Select placeholder="Select purpose" showSearch style={{ width: '100%' }} disabled>
                    <Select.Option value={IdentityPurpose.WebPush}>WebPush</Select.Option>
                    <Select.Option value={IdentityPurpose.Custom}>Custom</Select.Option>
                    <Select.Option value={IdentityPurpose.AzureWebPush}>Azure WebPush</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24}>
                <Form.Item label="Description" name="description">
                  <Input placeholder="Description" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24}>
                <Form.Item label="Type" name="type" rules={[{ required: true, message: 'Please select type!' }]}>
                  <Select
                    placeholder="Select type"
                    showSearch
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      setIsFixed(value === IdentityType.Fixed);
                    }}
                  >
                    <Select.Option value={IdentityType.Fixed}>Fixed</Select.Option>
                    <Select.Option value={IdentityType.Replacement}>Replacement</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={12}>
                <Form.Item label="Active domains number" name="active_domains_num">
                  <InputNumber placeholder="Active domains number" disabled={isFixed} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Replacement Interval" name="replacement_interval">
                  <InputNumber placeholder="Replacement Interval" disabled={isFixed} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24}>
                <Form.Item label="Tests" name="tests">
                  <Select
                    placeholder="Select tests"
                    optionFilterProp="data-searchvalue"
                    showSearch
                    allowClear
                    loading={testsLoading}
                    style={{ width: '100%' }}
                    mode="multiple"
                  >
                    {generateSelectOptionsWithIdValues(allTests)}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24}>
                <Form.Item label="Notify Users" name="notify_users">
                  <Select
                    mode="multiple"
                    placeholder="Select users"
                    allowClear
                    optionFilterProp="data-searchvalue"
                    loading={allAccountsLoading}
                    style={{ width: '100%' }}
                  >
                    {getAccountsSelectOptions(allAccounts)}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className="ant-modal-footer">
            <Button key="cancel" onClick={handleClose}>
              Cancel
            </Button>
            <Button key="submit" type="primary" loading={submitting} onClick={() => form.submit()}>
              Edit
            </Button>
          </div>
        </Col>
        <Col span={14}>
          <DomainsInfoTable
            loading={isFetching}
            identityId={id}
            domains={domains}
            totalItems={totalItems}
            currentPage={currentPage}
            pageSize={pageSize}
            onPaginationChange={(page) => setCurrentPage(page)}
            onDelete={() => {
              getDomains();
            }}
            onAdd={() => {
              getDomains();
            }}
            onSetAutoRenew={() => {
              getDomains();
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
};
