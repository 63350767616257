import React, { useEffect, useState } from 'react';
import { tiktokPixelsService } from '../../services/tiktok-pixels';
import { SortDirection } from '../../services/statistic';
import { useDebounce, useFetch } from '../../utils/hooks';

import { Header } from './components/Header';
import { Table } from './components/Table';

export const TiktokPixels = () => {
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [sorting, setSorting] = useState([{ key: 'created_at', value: SortDirection.Descending }]);

  const debouncedSearchValue = useDebounce(searchValue);

  const loadTiktokPixels = (cancelToken) =>
    tiktokPixelsService.getPaged({ currentPage, pageSize, searchValue: debouncedSearchValue, cancelToken, sorting });

  const [
    {
      data: { results: tiktokPixels, count: totalItems },
      isFetching
    },
    getTiktokPixels
  ] = useFetch(loadTiktokPixels);

  useEffect(() => {
    getTiktokPixels();
  }, [currentPage, debouncedSearchValue, getTiktokPixels, sorting]);

  useEffect(() => setCurrentPage(1), [debouncedSearchValue, sorting]);

  return (
    <div>
      <Header searchValue={searchValue} onSearch={setSearchValue} onAdd={getTiktokPixels} />

      <Table
        tiktokPixels={tiktokPixels}
        tiktokPixelsLoading={isFetching}
        totalItems={totalItems}
        currentPage={currentPage}
        pageSize={pageSize}
        sorting={sorting}
        onPaginationChange={(page) => setCurrentPage(page)}
        onEdit={getTiktokPixels}
        onDelete={getTiktokPixels}
        onSortingClick={setSorting}
      />
    </div>
  );
};
