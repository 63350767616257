import React, { useEffect, useState } from 'react';
import { Button, Col, Popover, Row } from 'antd';

import { PresetConfigModal } from './PresetConfigModal';
import { SaveAsOrEditPresetModal } from './SaveAsOrEditPresetModal';
import { FilterName, SourcesSearchType, statisticServices } from '../../../services/statistic';
import { showApiErrors } from '../../../utils/showApiErrors';
import { getChannelStatsPresets, useCustomQuery } from '../../../utils/hooks/queries';
import { generateSelectOptionsProp } from '../../../utils/options';
import { PresetSelect } from './PresetSelect';
import { openSuccessNotification } from '../../../utils/notifications';
import { auth } from '../../../services/auth';
import { expandPresetColumns, removeExtraFields } from '../utils/columns';

export const PresetColumnConfig = ({
  presetData,
  wrapperHeight = '100%',
  popoverPlacement = 'bottomRight',
  showUpdateButton,
  renderColumnRows,
  onUpdate,
  onPresetChange,
  onColumnsDataChange,
  onPresetLoading
}) => {
  const [presetConfigVisible, setPresetConfigVisible] = useState(false);
  const [showSaveAsModal, setShowSaveAsModal] = useState(false);
  const [columnsVisible, setColumnsVisible] = useState(false);
  const [selectedPresetId, setSelectedPresetId] = useState();
  const [selectedPresetDataLoading, setSelectedPresetDataLoading] = useState();

  const [submittingPreset, setSubmittingPreset] = useState(false);
  const [isPresetOnMount, setIsPresetOnMount] = useState(true);

  const {
    isLoading: presetsLoading,
    isFetching: presetsRefetching,
    data: presets,
    refetch: refetchPresets
  } = useCustomQuery(getChannelStatsPresets);

  const currentUserDefaultPresetId = auth.user?.default_preset_id;
  const saveAsDisabled = !selectedPresetId;
  const saveDisabled = saveAsDisabled || !statisticServices.hasPresetEditPermission(presetData?.creator);

  const handleColumnsDataChange = (index, fieldName, value) => {
    onColumnsDataChange((oldValues) => {
      const newValues = { ...oldValues };
      newValues.data.columns[index][fieldName] = value;
      return newValues;
    });
  };

  const handleDeleteColumns = (index) => {
    onColumnsDataChange((oldValues) => {
      const newValues = { ...oldValues };
      const newColumns = [...newValues.data.columns];
      newColumns.splice(index, 1);
      return { ...newValues, data: { ...newValues.data, columns: newColumns } };
    });
  };

  const handleSave = async () => {
    const newColumns = removeExtraFields(presetData.data.columns);
    const requestData = { ...presetData, data: { ...presetData.data, columns: newColumns } };
    try {
      setSubmittingPreset(true);
      onPresetLoading(true);
      await statisticServices.editChannelStatsPreset(selectedPresetId, requestData);
      openSuccessNotification({ message: 'Successfully saved preset changes!' });
      refetchPresets();
    } catch (e) {
      showApiErrors(e);
    } finally {
      setSubmittingPreset(false);
      onPresetLoading(false);
    }
  };

  const handleColumnsDataStateChange = (columns) => {
    onPresetChange({ ...presetData, data: { ...presetData.data, columns } });
  };

  const handleCloseModal = () => setShowSaveAsModal(false);

  const renderColumns = () => {
    return (
      <div
        style={{
          width: '800px',
          height: wrapperHeight,
          overflowY: 'auto'
        }}
      >
        {renderColumnRows()}
        <br />
        {!showUpdateButton ? null : (
          <Row gutter={16} style={{ position: 'sticky', right: 0, bottom: 0, justifyContent: 'flex-end', backgroundColor: '#fff' }}>
            <Col>
              <Button
                onClick={() => {
                  setColumnsVisible(false);
                  setPresetConfigVisible(true);
                }}
              >
                Configure
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  setColumnsVisible(false);
                  onUpdate();
                }}
                type="primary"
              >
                Ok
              </Button>
            </Col>
          </Row>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!selectedPresetId) {
      setSelectedPresetId(currentUserDefaultPresetId || presets?.[0]?.id);
    }
  }, [selectedPresetId, currentUserDefaultPresetId, presets]);

  useEffect(() => {
    if (isPresetOnMount) {
      setIsPresetOnMount(false);
    }
  }, [isPresetOnMount]);

  useEffect(() => {
    if (selectedPresetId) {
      (async () => {
        try {
          setSelectedPresetDataLoading(true);
          const savedPresetData = await statisticServices.getChannelStatsPresetById(selectedPresetId);
          const presetColumns = expandPresetColumns(savedPresetData.data.columns);
          const newPresetData = { ...savedPresetData, data: { ...savedPresetData.data, columns: presetColumns } };
          newPresetData.data.filters = newPresetData.data.filters || {};
          newPresetData.data.filters[FilterName.SourcesSearchType] =
            newPresetData.data.filters[FilterName.SourcesSearchType] || SourcesSearchType.Exact;
          onPresetChange(newPresetData);
        } catch (e) {
          showApiErrors(e);
        } finally {
          setSelectedPresetDataLoading(false);
        }
      })();
    }
  }, [selectedPresetId, onPresetChange]);

  return (
    <Row gutter={32}>
      <Col>
        <Popover
          placement={popoverPlacement}
          trigger="click"
          visible={columnsVisible}
          onVisibleChange={() => setColumnsVisible((prevState) => !prevState)}
          content={renderColumns()}
        >
          <Button type="primary" ghost style={{ width: '100%' }}>
            Columns
          </Button>
        </Popover>
      </Col>
      <Col style={{ width: '250px' }}>
        <PresetSelect
          options={generateSelectOptionsProp({ options: presets, additionalFields: ['creator', 'assigned_users', 'is_public'] })}
          loading={presetsLoading || presetsRefetching}
          selectValue={selectedPresetId}
          onChange={setSelectedPresetId}
          onPresetEdit={refetchPresets}
          onPresetDelete={refetchPresets}
        />
      </Col>
      <Col>
        <Button type="primary" loading={submittingPreset} disabled={saveDisabled} onClick={handleSave}>
          Save
        </Button>
      </Col>
      <Col>
        <Button type="primary" disabled={saveAsDisabled} onClick={() => setShowSaveAsModal(true)}>
          Save as...
        </Button>
        <SaveAsOrEditPresetModal
          visible={showSaveAsModal}
          saveAsPresetData={presetData}
          saveAsPresetDataLoading={selectedPresetDataLoading}
          onSuccess={() => {
            handleCloseModal();
            refetchPresets();
          }}
          onCancel={handleCloseModal}
        />
      </Col>
      <PresetConfigModal
        visible={presetConfigVisible}
        columnsData={presetData?.data?.columns || []}
        onClose={() => setPresetConfigVisible(false)}
        onAddNewColumn={handleColumnsDataStateChange}
        onDeleteColumn={handleDeleteColumns}
        onSortEnd={handleColumnsDataStateChange}
        onColumnsDataChange={handleColumnsDataChange}
      />
    </Row>
  );
};
