import { callApi } from './api';
import { getQueryString, getOffsetLimitFromPagination } from '../utils/query';
import { handleOrdering } from '../utils/functions';
import { ChannelColumnType, hiddenColumns } from '../pages/channel-stats/utils/columns';
import { auth } from './auth';

export const FilterName = {
  OSs: 'os',
  OSVersions: 'os_version',
  Browsers: 'browser',
  BrowserVersions: 'browser_version',
  DeviceTypes: 'device_type',
  Identities: 'identity_id',
  Countries: 'country',
  Channels: 'campaign_id',
  LPs: 'lp_id',
  RuleTypes: 'rule_type_id',
  Types: 'type',
  Products: 'product_id',
  AccountManagers: 'creator_id',
  TrafficCompanies: 'adv_company_id',
  ProductCompanies: 'prod_company_id',
  NonProfit: 'non_profit',
  Sources: 'source',
  SourcesSearchType: 'sources_search_type',
  PublisherModules: 'pub_module',
  CampaignTypes: 'campaign_type',
  CampaignTitles: 'campaign_title',
  CampaignStatuses: 'campaign_status',
  Sources1: 'source_1',
  Sources2: 'source_2',
  Sources3: 'source_3',
  Sources4: 'source_4',
  Sources5: 'source_5',
  Sources6: 'source_6',
  Sources7: 'source_7',
  Sources8: 'source_8',
  Sources9: 'source_9',
  CompanyCategory: 'company_category',
  Excluded: 'excluded',
  IncludeErrors: 'include_errors',
  BroughtBy: 'company_brought_by',
  Tags: 'tags',
  Domains: 'host'
};

export const SortDirection = {
  Descending: 'descend',
  Ascending: 'ascend'
};

export const CampaignType = {
  CPA: 'CPA',
  CPM: 'CPM',
  CPC: 'CPC',
  RS: 'RS'
};

export const SourcesSearchType = {
  Contains: 'contains',
  Exact: 'exact'
};

const getColumnsAndColumnsNamesParameters = (columns, checkedColumns) => {
  const columnsValues = [];
  const columnsNames = [];
  columns.forEach(({ value, text, newText }) => {
    if (checkedColumns[value]) {
      columnsValues.push(value);
      columnsNames.push(newText || text);
    }
  });

  hiddenColumns.forEach(({ value, text }) => {
    if (checkedColumns[value]) {
      columnsValues.push(value);
      columnsNames.push(text);
    }
  });

  return {
    columns: columnsValues.join(','),
    columns_custom_names: columnsNames.join(',')
  };
};

const getCustomColumns = (columns, checkedColumns) =>
  columns.filter(({ type, value }) => type === ChannelColumnType.Custom && checkedColumns[value]);

const getCustomColumnsFormulasQuery = (customColumns) => {
  const customColumnValues = [];
  const params = {};

  customColumns.forEach(({ value, formula }) => {
    customColumnValues.push(value);
    params[`${value}__formula`] = formula;
  });
  if (customColumnValues.length) {
    params['custom_formulas'] = customColumnValues.join(',');
  }

  return params;
};

class StatisticServices {
  async channelChart(filters, perHour, dateRange, includeErrors) {
    const { metrics } = filters;
    const perHourQuery = perHour ? ',hour' : '';
    const metricsQuery = metrics ? ',' + metrics.join(',') : '';

    let query = getQueryString(
      {
        columns: `date${perHourQuery}${metricsQuery}`,
        date_from: dateRange.from.format('YYYY-MM-DD'),
        date_to: dateRange.to.format('YYYY-MM-DD'),
        os: filters[FilterName.OSs] && filters[FilterName.OSs].join(','),
        os_version: filters[FilterName.OSVersions] && filters[FilterName.OSVersions].join(','),
        browser: filters[FilterName.Browsers] && filters[FilterName.Browsers].join(','),
        country: filters[FilterName.Countries] && filters[FilterName.Countries].join(','), // ex filters.countries
        campaign_id: filters[FilterName.Channels] && filters[FilterName.Channels].join(','), // ex filters.channels
        lp_id: filters[FilterName.LPs] && filters[FilterName.LPs].join(','), // ex filters.lps_id,
        product_id: filters[FilterName.Products] && filters[FilterName.Products].join(','), // ex filters.products
        creator_id: filters[FilterName.AccountManagers] && filters[FilterName.AccountManagers].join(','),
        adv_company_id: filters[FilterName.TrafficCompanies] && filters[FilterName.TrafficCompanies].join(','),
        prod_company_id: filters[FilterName.ProductCompanies] && filters[FilterName.ProductCompanies].join(','),
        source: filters[FilterName.Sources] && filters[FilterName.Sources].join(','),
        pub_module: filters[FilterName.PublisherModules] && filters[FilterName.PublisherModules].join(','),
        skip_errors: !includeErrors
      },
      true
    );

    // NOTE: quick hack for old stats
    const oldStats = new URLSearchParams(window.location.search).get('old_stats');
    const url = oldStats !== null ? 'stats' : 'ccss_stats';
    return await callApi({ url: `/api/channels/${url}?resType=chart&${query}` });
  }

  async getFlaggingStatsPaged({ selectedFilters, currentPage, pageSize, sorting, cancelToken }) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    const colParameter = Object.keys(checkedColumns)
      .filter((key) => checkedColumns[key])
      .join(',');
    const ordering = handleOrdering(sorting);
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      fields: colParameter,
      date_from: dateRange.from.format('YYYY-MM-DD'),
      date_to: dateRange.to.format('YYYY-MM-DD'),
      identity__in: filters.identity && filters.identity.join(','),
      test__in: filters.tests && filters.tests.join(','),
      search_input: filters.searchValue,
      ordering: ordering.join(',')
    });
    return await callApi({ url: `/api/domains/phishing/${query}`, cancelToken });
  }

  getChannelsQuery = ({ statsRelatedData, asyncExport, sorting, checkedColumns }) => {
    const { columns, filters, dateRange } = statsRelatedData;
    const ordering = handleOrdering(sorting);

    checkedColumns = checkedColumns || statsRelatedData.checkedColumns;

    const queryData = {
      ...getColumnsAndColumnsNamesParameters(columns, checkedColumns),
      date_from: dateRange.from.format('YYYY-MM-DD'),
      date_to: dateRange.to.format('YYYY-MM-DD'),
      os: filters[FilterName.OSs] && filters[FilterName.OSs].join(','),
      os_version: filters[FilterName.OSVersions] && filters[FilterName.OSVersions].join(','),
      browser: filters[FilterName.Browsers] && filters[FilterName.Browsers].join(','),
      browser_version: filters[FilterName.BrowserVersions] && filters[FilterName.BrowserVersions].join(','),
      device_type: filters[FilterName.DeviceTypes] && filters[FilterName.DeviceTypes].join(','),
      identity_id: filters[FilterName.Identities] && filters[FilterName.Identities].join(','),
      host: filters[FilterName.Domains] && filters[FilterName.Domains].join(','),
      country: filters[FilterName.Countries] && filters[FilterName.Countries].join(','), // ex filters.countries
      campaign_id: filters[FilterName.Channels] && filters[FilterName.Channels].join(','), // ex filters.channels
      ordering: ordering && ordering.join(','),
      lp_id: filters[FilterName.LPs] && filters[FilterName.LPs].join(','), // ex filters.lps_id
      rule_type_id: filters[FilterName.RuleTypes] && filters[FilterName.RuleTypes].join(','),
      type: filters[FilterName.Types] && filters[FilterName.Types].join(','),
      product_id: filters[FilterName.Products] && filters[FilterName.Products].join(','), // ex filters.products
      creator_id: filters[FilterName.AccountManagers] && filters[FilterName.AccountManagers].join(','),
      adv_company_id: filters[FilterName.TrafficCompanies] && filters[FilterName.TrafficCompanies].join(','),
      prod_company_id: filters[FilterName.ProductCompanies] && filters[FilterName.ProductCompanies].join(','),
      company_brought_by: filters[FilterName.BroughtBy] && filters[FilterName.BroughtBy].join(','),
      tags: filters[FilterName.Tags],
      non_profit: filters[FilterName.NonProfit],
      pub_module: filters[FilterName.PublisherModules] && filters[FilterName.PublisherModules].join(','),
      campaign_type: filters[FilterName.CampaignTypes] && filters[FilterName.CampaignTypes].join(','),
      campaign_title: filters[FilterName.CampaignTitles] && filters[FilterName.CampaignTitles].join(','),
      campaign_status: filters[FilterName.CampaignStatuses] && filters[FilterName.CampaignStatuses].join(','),
      source_1: filters[FilterName.Sources1] && filters[FilterName.Sources1].join(','),
      source_2: filters[FilterName.Sources2] && filters[FilterName.Sources2].join(','),
      source_3: filters[FilterName.Sources3] && filters[FilterName.Sources3].join(','),
      source_4: filters[FilterName.Sources4] && filters[FilterName.Sources4].join(','),
      source_5: filters[FilterName.Sources5] && filters[FilterName.Sources5].join(','),
      source_6: filters[FilterName.Sources6] && filters[FilterName.Sources6].join(','),
      source_7: filters[FilterName.Sources7] && filters[FilterName.Sources7].join(','),
      source_8: filters[FilterName.Sources8] && filters[FilterName.Sources8].join(','),
      source_9: filters[FilterName.Sources9] && filters[FilterName.Sources9].join(','),
      company_category: filters[FilterName.CompanyCategory],
      async_export: asyncExport,
      excluded: filters[FilterName.Excluded] && filters[FilterName.Excluded].join(','),
      skip_errors: !filters[FilterName.IncludeErrors]
    };

    const sources = filters[FilterName.Sources] && filters[FilterName.Sources].join(',');
    if (filters[FilterName.SourcesSearchType] === SourcesSearchType.Contains) {
      queryData.source__contains = sources;
    } else {
      queryData.source = sources;
    }

    return queryData;
  };

  async getChannelsPaged({ statsRelatedData, asyncExport, currentPage, pageSize, sorting, cancelToken }) {
    const { columns } = statsRelatedData;
    let { checkedColumns } = statsRelatedData;

    if (checkedColumns.channel && !checkedColumns.campaign_id) {
      checkedColumns = { ...checkedColumns, campaign_id: true };
    }

    if (checkedColumns['creator_first_name']) {
      checkedColumns = { ...checkedColumns, creator_last_name: true };
    }
    if (checkedColumns['prod_brought_by_first_name']) {
      checkedColumns = { ...checkedColumns, prod_brought_by_last_name: true };
    }
    if (checkedColumns['adv_brought_by_first_name']) {
      checkedColumns = { ...checkedColumns, adv_brought_by_last_name: true };
    }

    const customColumns = getCustomColumns(columns, checkedColumns);

    let query = getQueryString(
      {
        ...this.getChannelsQuery({ statsRelatedData, asyncExport, sorting, checkedColumns }),
        ...getOffsetLimitFromPagination({ pageSize, currentPage }),
        ...getCustomColumnsFormulasQuery(customColumns)
      },
      true
    );

    // NOTE: quick hack for old stats
    const oldStats = new URLSearchParams(window.location.search).get('old_stats');
    const url = oldStats !== null ? 'stats' : 'ccss_stats';
    return await callApi({
      url: `/api/channels/${url}/?resType=table&${query}`,
      cancelToken
    });
  }

  getChannelsExportUrl({ statsRelatedData, sorting, resType }) {
    const query = getQueryString({ ...this.getChannelsQuery({ statsRelatedData, sorting }) }, true);
    // const customColumnsQuery = getCustomColumnsQuery(customColumns, checkedColumns);

    // NOTE: quick hack for old stats
    const oldStats = new URLSearchParams(window.location.search).get('old_stats');
    const url = oldStats !== null ? 'stats' : 'ccss_stats';
    return `/api/channels/${url}/?resType=${resType}&${query}`;
  }

  async getGoogleSheetUrl({ statsRelatedData, sorting }) {
    return (
      await callApi({
        url: this.getChannelsExportUrl({ statsRelatedData, sorting, resType: 'google_sheet' })
      })
    ).url;
  }

  async getChannelStatsPresets() {
    return await callApi({ url: '/api/channel_stats_presets/?fields=id,name,creator,assigned_users,is_public' });
  }

  async getChannelStatsPresetById(id) {
    return await callApi({ url: `/api/channel_stats_presets/${id}/` });
  }

  async addChannelStatsPreset(data) {
    return await callApi({ url: '/api/channel_stats_presets/', method: 'POST', data });
  }

  async setDefaultChannelStatsPreset(id) {
    return await callApi({ url: '/api/channel_stats_presets/set_default_preset_id/', method: 'POST', data: { default_preset_id: id } });
  }

  async editChannelStatsPreset(id, data) {
    return await callApi({ url: `/api/channel_stats_presets/${id}/`, method: 'PATCH', data });
  }

  async deleteChannelStatsPreset(id) {
    return await callApi({ url: `/api/channel_stats_presets/${id}/`, method: 'DELETE' });
  }

  hasPresetEditPermission(creator_id) {
    return auth.userPermissions.isAdmin || creator_id === auth.user?.id || auth.hasPermission('edit_not_owned_presets');
  }

  isOwnPreset(preset) {
    return preset.is_public || preset.creator === auth.user?.id || preset.assigned_users.includes(auth.user?.id);
  }

  canViewAllPresets() {
    return auth.hasPermission('view_all_presets');
  }
}

export const statisticServices = new StatisticServices();
