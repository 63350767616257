import { Button, Col, Form, Input, Modal, Row } from 'antd';

import React, { useMemo, useState, useEffect } from 'react';
import { tiktokPixelsService } from '../../../services/tiktok-pixels';
import { confirmClose } from '../../../utils/confirmClose';
import { openSuccessNotification } from '../../../utils/notifications';
import { showApiErrors } from '../../../utils/showApiErrors';
import { Spinner } from '../../../components/Spinner';

export const AddOrEditTiktokPixel = ({ modalVisible, tiktokPixelId, onClose, onAdd, onEdit }) => {
  const [form] = Form.useForm();
  const [wasFormChanged, setWasFormChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [tiktokPixel, setTiktokPixel] = useState();
  const [tiktokPixelLoading, setTiktokPixelLoading] = useState(false);

  const action = tiktokPixelId ? 'Edit' : 'Add';

  const initialValues = useMemo(() => {
    if (!tiktokPixel) {
      return {};
    }

    return tiktokPixel;
  }, [tiktokPixel]);

  useEffect(() => {
    form.resetFields();
    setWasFormChanged(false);
  }, [form, initialValues, modalVisible]);

  useEffect(() => {
    const fetchPixel = async () => {
      if (tiktokPixelId && modalVisible) {
        setTiktokPixelLoading(true);
        try {
          const pixel = await tiktokPixelsService.getSingle(tiktokPixelId);
          setTiktokPixel(pixel);
        } catch (e) {
          showApiErrors(e);
        } finally {
          setTiktokPixelLoading(false);
        }
      } else {
        setTiktokPixel(undefined);
      }
    };
    fetchPixel();
  }, [tiktokPixelId, modalVisible]);

  const handleClose = () => {
    wasFormChanged ? confirmClose(onClose) : onClose();
  };

  const handleSubmit = async (values) => {
    try {
      setSubmitting(true);

      if (tiktokPixelId) {
        await tiktokPixelsService.edit(values, tiktokPixelId);
        openSuccessNotification({ message: 'Successfully edited tiktok pixel' });
        onEdit();
      } else {
        await tiktokPixelsService.add(values);
        openSuccessNotification({ message: 'Successfully added tiktok pixel' });
        onAdd();
      }
    } catch (e) {
      showApiErrors(e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title={`${action} Tiktok Pixel`}
      visible={modalVisible}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={submitting} onClick={() => form.submit()}>
          {action}
        </Button>
      ]}
      width={600}
    >
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        layout="vertical"
        onFinishFailed={({ errorFields }) => {
          form.scrollToField(errorFields[0].name);
        }}
        onValuesChange={() => setWasFormChanged(true)}
      >
        {tiktokPixelLoading ? (
          <Spinner />
        ) : (
          <>
            <Row gutter={32}>
              <Col span={24}>
                <Form.Item label="Pixel Id" name="id" rules={[{ required: true, message: 'Please input pixel id!' }]}>
                  <Input placeholder="Pixel Id" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24}>
                <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input pixel name!' }]}>
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24}>
                <Form.Item label="Access Token" name="token" rules={[{ required: true, message: 'Please input access token!' }]}>
                  <Input placeholder="Access Token" />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Modal>
  );
};
