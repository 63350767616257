import { Modal, Button, Form, Divider, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { confirmClose } from '../../../utils/confirmClose';
import { tiktokPixelsService } from '../../../services/tiktok-pixels';
import { showApiErrors } from '../../../utils/showApiErrors';
import { getSelectOptionsWithIdValues } from '../utils/options';
import { Select } from '../../../components/Select';

const tiktokEventNames = [
  'AddPaymentInfo',
  'AddToCart',
  'AddToWishlist',
  'ClickButton',
  'CompletePayment',
  'CompleteRegistration',
  'Contact',
  'CustomizeProduct',
  'Download',
  'FindLocation',
  'InitiateCheckout',
  'PlaceAnOrder',
  'Schedule',
  'Search',
  'SubmitForm',
  'Subscribe',
  'ViewContent'
];

const tiktokEventNamesItems = tiktokEventNames.map((n) => ({ id: n, name: n }));

export const TiktokS2SIntegrationModal = ({ modalVisible, onCancel, onGenerate }) => {
  const [wasFormChanged, setWasFormChanged] = useState(false);

  const [loadingPixels, setLoadingPixels] = useState(false);
  const [allPixels, setAllPixels] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    const getPixels = async () => {
      setLoadingPixels(true);
      try {
        const pixels = await tiktokPixelsService.getAll();
        setAllPixels(pixels);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoadingPixels(false);
      }
    };

    if (modalVisible) {
      form.resetFields();
      setWasFormChanged(false);
      getPixels();
    }
  }, [form, modalVisible]);

  const handleCancel = () => {
    wasFormChanged ? confirmClose(() => onCancel()) : onCancel();
  };

  return (
    <Modal
      title="Generate Tiktok S2S Url"
      visible={modalVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          Generate S2S Url
        </Button>
      ]}
    >
      <Form
        form={form}
        onFinish={({ pixelId, eventName, eventSourceUrl }) => {
          const valueParam = eventName === 'CompletePayment' ? '&value={LEAD_PRICE}' : '';
          const encodedEventSourceUrl = encodeURIComponent(eventSourceUrl);
          const url = `https://tt-pixel.affflow.com/?pixelId=${pixelId}&ttclid={CONVERSION_ID}&eventName=${eventName}${valueParam}&ip={IM_REMOTE_ADDR}&userAgent={IM_USER_AGENT}&url=${encodedEventSourceUrl}`;
          onGenerate(url);
        }}
        onFinishFailed={({ errorFields }) => {
          form.scrollToField(errorFields[0].name);
        }}
        onValuesChange={() => setWasFormChanged(true)}
        layout="vertical"
      >
        <Form.Item
          label="Pixel"
          name="pixelId"
          rules={[
            {
              required: true,
              message: 'Please select a pixel!'
            }
          ]}
        >
          <Select
            placeholder="Select tiktok pixel"
            optionFilterProp="data-searchvalue"
            showSearch
            loading={loadingPixels}
            style={{ width: '100%' }}
          >
            {getSelectOptionsWithIdValues(allPixels)}
          </Select>
        </Form.Item>

        <Form.Item
          label="Event"
          name="eventName"
          rules={[
            {
              required: true,
              message: 'Please select an event!'
            }
          ]}
        >
          <Select placeholder="Select tiktok event" optionFilterProp="data-searchvalue" showSearch style={{ width: '100%' }}>
            {getSelectOptionsWithIdValues(tiktokEventNamesItems)}
          </Select>
        </Form.Item>

        <Form.Item
          label="Event source url"
          name="eventSourceUrl"
          rules={[
            {
              required: true,
              message: 'Please type a url!'
            }
          ]}
        >
          <Input type="url" placeholder="https://some-domain.com" />
        </Form.Item>

        <Divider />
        <div style={{ color: 'blue' }}>
          NOTE: type <b>ttclid</b> for "Click Parameter" in Basic tab
        </div>
      </Form>
    </Modal>
  );
};
