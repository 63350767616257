import { Dropdown, Menu, Pagination, Row, Table } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { TableTitle } from '../../components/TableTitle';
import { statisticServices, SortDirection } from '../../services/statistic';
import { useFetch } from '../../utils/hooks/useFetch';
import { openWarningNotification } from '../../utils/notifications';
import { showApiErrors } from '../../utils/showApiErrors';
import styles from './ChannelStats.module.css';
import { Filters } from './components/Filters';

export const Channels = () => {
  const [currentPresetData, setCurrentPresetData] = useState();
  const [requestRelatedData, setRequestRelatedData] = useState({});
  const [tableColumns, setTableColumns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [loadingExportButton, setLoadingExportButton] = useState(false);

  const pageSizeOptions = [20, 40, 60, 80];

  const [sorting, setSorting] = useState([]);

  const sumOfDataRefactor = (sumOfData, allColumns) => {
    if (sumOfData) {
      if (sumOfData[0]) {
        let sumOfDataRefactored = { ...sumOfData[0] };
        allColumns.forEach((column) => {
          if (sumOfDataRefactored[column.value] !== undefined) {
            if (['metric', 'derived_metric', 'custom'].includes(column.type)) {
              const decimalParsed = parseFloat(sumOfDataRefactored[column.value])?.toFixed(column.decimal_places || 0);
              const outputParsed = parseValueByOutput(decimalParsed, column.output);
              sumOfDataRefactored[column.value] = outputParsed;
            }
          }
        });

        return sumOfDataRefactored;
      }
    }
  };

  const parseValueByOutput = (value, type) => {
    switch (type) {
      case 'currency':
        return '$ ' + value;
      case 'percent':
        return value + ' %';
      default:
        return value;
    }
  };

  const refactorTableData = (tableData, allChannelStatisticsColumns) => {
    const tableDataRefactored = tableData
      ? tableData.map((el, index) => {
          const refactor = { ...el };
          if (refactor.channel) {
            refactor.channel = (
              <div>
                <Dropdown
                  overlayStyle={{ width: '200px' }}
                  overlay={
                    <Menu>
                      <Menu.Item key="1">
                        <Link to={`/media-campaigns/${refactor.campaign_id}`} target="_blank">
                          Edit channel
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="2">
                        <Link target="_blank" to={`/media-campaigns/clone?campaignId=${refactor.campaign_id}`}>
                          Clone channel
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="3">
                        <Link to={`/stubs/channel_chart/?channel=${refactor.campaign_id}`}>Graph view</Link>
                      </Menu.Item>
                      <Menu.Item key="4">
                        <Link
                          target="_blank"
                          to={`/stubs/channel_stats/?channel=${refactor.campaign_id}&date_from=${requestRelatedData.dateRange.from.format(
                            'YYYY-MM-DD'
                          )}&date_to=${requestRelatedData.dateRange.to.format(
                            'YYYY-MM-DD'
                          )}&columns=channel,lp_id,product_title,im,dw,pl,pf,spent,earned,profit,roi,rpm,cr`}
                        >
                          Performance check
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="5">
                        <Link
                          target="_blank"
                          to={`/stubs/channel_stats/?channel=${
                            refactor.campaign_id
                          }&columns=channel,country,im,dw,pl,pf,spent,earned,profit,roi,cr,pcr&date_from=${requestRelatedData.dateRange.from.format(
                            'YYYY-MM-DD'
                          )}&date_to=${requestRelatedData.dateRange.to.format('YYYY-MM-DD')}`}
                        >
                          Country breakdown
                        </Link>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <span style={{ cursor: 'pointer' }}>
                    <img src="/images/plus.png" alt="Delete" width="21px" />
                  </span>
                </Dropdown>
                &nbsp; {refactor.channel}
              </div>
            );
          }
          if (refactor.country) {
            refactor.country = (
              <Row>
                <img
                  style={{ alignSelf: 'center' }}
                  alt={refactor.country}
                  src={`/media/countries/${refactor.country.toLowerCase()}.png`}
                />
                <span>&nbsp;{refactor.country}</span>
              </Row>
            );
          }
          refactor.creator_first_name = refactor.creator_first_name + ' ' + refactor.creator_last_name;

          allChannelStatisticsColumns.forEach((column) => {
            if (refactor[column.value] !== undefined) {
              if (['metric', 'derived_metric', 'custom'].includes(column.type)) {
                const decimalParsed = parseFloat(refactor[column.value])?.toFixed(column.decimal_places || 0);
                const outputParsed = parseValueByOutput(decimalParsed, column.output);
                refactor[column.value] = outputParsed;
              }
            }
          });

          refactor.prod_brought_by_first_name = refactor.prod_brought_by_first_name + ' ' + refactor.prod_brought_by_last_name;
          refactor.adv_brought_by_first_name = refactor.adv_brought_by_first_name + ' ' + refactor.adv_brought_by_last_name;
          refactor.date = refactor.date ? moment(refactor.date, 'YYYYMMDD').format('DD.MM.YYYY.') : '';
          return {
            key: index,
            ...refactor
          };
        })
      : '';

    return tableDataRefactored;
  };

  const loadStatsData = async (cancelToken) => {
    const asyncExport = !!(requestRelatedData?.dateRange?.to.diff(requestRelatedData?.dateRange?.from, 'months') >= 2);

    const response = await statisticServices.getChannelsPaged({
      statsRelatedData: { ...requestRelatedData, columns },
      asyncExport,
      // customColumns,
      currentPage,
      pageSize,
      sorting,
      cancelToken
    });

    if (asyncExport) {
      openWarningNotification({
        message:
          "You requested a lot of data. No worries, we'll send you an e-mail cointaining stats you required. Make sure you check it in the next 48h."
      });
    }

    return response;
  };

  const total = [];
  const table = [];
  const rows = 0;

  const [
    {
      data: { total: sumOfData, table: tableData, rows: totalItems },
      isFetching
    },
    getStatsData
  ] = useFetch(loadStatsData, { table, total, rows });

  const loading = isFetching;

  const columns = currentPresetData?.data?.columns || [];

  useEffect(() => setCurrentPage(1), [pageSize, requestRelatedData]);

  useEffect(() => {
    if (requestRelatedData.filters) {
      getStatsData();
    }
  }, [sorting, pageSize, requestRelatedData, getStatsData, currentPage]);

  const mobileViewData = (record) => {
    let dataMobile = [];
    let checkedColumnsObj = {};
    columns.forEach((column) => {
      if (requestRelatedData.checkedColumns[column.value]) {
        checkedColumnsObj[column.value] = column.newText || column.text;
      }
    });
    if (record !== undefined) {
      for (let key in record) {
        if (record.hasOwnProperty(key) && checkedColumnsObj[key]) {
          dataMobile.push(
            <div className={styles.mobileViewData} key={key}>
              {checkedColumnsObj[key]}:<span style={{ width: '10px' }} /> {record[key]}
            </div>
          );
        }
      }
    } else {
      for (let key in total) {
        if (total.hasOwnProperty(key) && key in requestRelatedData.checkedColumns) {
          dataMobile.push(
            <div className={styles.mobileViewData} key={key}>
              total_{checkedColumnsObj[key]}:<span style={{ width: '10px' }} /> {total[key]}
            </div>
          );
        }
      }
    }
    return dataMobile;
  };

  const updateColumns = useCallback(
    (value) => {
      setTableColumns(() => {
        let newTableColumn = [];
        columns
          .filter((column) => value.checkedColumns[column['value']])
          .forEach(function ({ text, newText, value }) {
            newTableColumn.push({
              title: (
                <TableTitle
                  text={newText || text}
                  order={sorting.find((s) => s.key === value)?.value}
                  value={value}
                  onSortingClick={setSorting}
                />
              ),
              dataIndex: value
            });
          });
        return newTableColumn;
      });
    },
    [columns, sorting]
  );

  useEffect(() => {
    if (requestRelatedData.filters) {
      updateColumns(requestRelatedData);
    }
  }, [sorting, updateColumns, requestRelatedData]);

  const handleExport = async ({ filters, checkedColumns, dateRange, type }) => {
    try {
      setLoadingExportButton(true);
      let url;
      if (type === 'csv') {
        url = statisticServices.getChannelsExportUrl({
          statsRelatedData: { filters, checkedColumns, dateRange, columns },
          sorting,
          resType: type
        });
      } else {
        url = await statisticServices.getGoogleSheetUrl({
          statsRelatedData: { filters, checkedColumns, dateRange, columns },
          sorting
        });
      }
      window.open(url);
    } catch (e) {
      showApiErrors(e);
    } finally {
      setLoadingExportButton(false);
    }
  };

  const setDefaultSorting = (checkedColumns) => {
    if (checkedColumns.date && checkedColumns.hour) {
      setSorting([
        { key: 'date', value: SortDirection.Descending },
        { key: 'hour', value: SortDirection.Descending }
      ]);
    } else if (checkedColumns.date) {
      setSorting([{ key: 'date', value: SortDirection.Descending }]);
    } else if (checkedColumns.hour) {
      setSorting([{ key: 'hour', value: SortDirection.Descending }]);
    } else if (checkedColumns.spent) {
      setSorting([{ key: 'spent', value: SortDirection.Descending }]);
    }
  };

  return (
    <div className="Reports">
      <h1>Channel stats</h1>
      <Filters
        currentPresetData={currentPresetData}
        onPresetDataChange={setCurrentPresetData}
        onSubmit={(value) => {
          setDefaultSorting(value.checkedColumns);
          setRequestRelatedData(value);
        }}
        updateLoading={loading}
        onExport={handleExport}
        loadingExportButton={loadingExportButton}
      />
      <Table
        style={{ marginTop: '2%' }}
        columns={tableColumns}
        dataSource={refactorTableData(tableData, columns)}
        rowKey={({ key }) => key}
        loading={loading}
        pagination={false}
        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
        expandedRowRender={
          window.innerWidth < 700 ? (record) => mobileViewData(refactorTableData()[record.key], tableColumns, total) : null
        }
        summary={() => {
          const sumOfDataRefactored = sumOfDataRefactor(sumOfData, columns);
          const sum =
            sumOfData && sumOfData[0] ? (
              <Table.Summary.Row>
                {tableColumns.map((el, index) => (
                  <Table.Summary.Cell key={index} index={index}>
                    {sumOfDataRefactored[el.dataIndex]}
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            ) : null;
          return sum;
        }}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={(value) => setCurrentPage(value)}
              showSizeChanger={true}
              pageSize={pageSize}
              onShowSizeChange={(curr, value) => {
                setPageSize(value);
              }}
              pageSizeOptions={pageSizeOptions}
            />
          );
        }}
        rowClassName={(record) => {
          const classNames = [];
          // moments method day() returns 6 and 0 for Saturday and Sunday respectively
          if (record.date && !(moment(record.date, 'DD.MM.YYYY.').day() % 6)) {
            classNames.push(styles.weekend);
          }

          if (record.profit && record.profit !== 0) {
            let profit = record.profit.split('$').pop();
            if (profit < 0) {
              classNames.push(styles.negative);
            }
          }

          return classNames.join(' ');
        }}
      />
    </div>
  );
};
