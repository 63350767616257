import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import { confirmClose } from '../../../utils/confirmClose';
import { openSuccessNotification } from '../../../utils/notifications';
import { identityService } from '../../../services/identity';
import { showApiErrors } from '../../../utils/showApiErrors';
import { Select } from '../../../components/Select';
import { generateOptions } from '../../../utils/options';

export const AddDomainModal = ({ modalVisible, identityId, onCancel, onAdd }) => {
  const [form] = Form.useForm();
  const [wasFormChanged, setWasFormChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [loadingDomains, setLoadingDomains] = useState(false);
  const [availableDomains, setAvailableDomains] = useState([]);

  useEffect(() => {
    const getAvailableDomains = async () => {
      setLoadingDomains(true);
      try {
        const domains = await identityService.getAvailableDomains(identityId);
        setAvailableDomains(domains);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoadingDomains(false);
      }
    };

    if (modalVisible) {
      form.resetFields();
      setWasFormChanged(false);
      getAvailableDomains();
    }
  }, [form, modalVisible, identityId]);

  const handleClose = () => {
    wasFormChanged ? confirmClose(onCancel) : onCancel();
  };

  const handleSubmit = async (values) => {
    try {
      setSubmitting(true);
      await identityService.addDomain(identityId, values.domainId);
      openSuccessNotification({ message: 'Successfully added domain' });
      onAdd();
    } catch (e) {
      showApiErrors(e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title={'Add domain to identity'}
      visible={modalVisible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={submitting} onClick={() => form.submit()}>
          Add
        </Button>
      ]}
      width={600}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        onFinishFailed={({ errorFields }) => {
          form.scrollToField(errorFields[0].name);
        }}
        onValuesChange={() => setWasFormChanged(true)}
      >
        <Form.Item
          label="Domain"
          name="domainId"
          rules={[
            {
              required: true,
              message: 'Please select a domain!'
            }
          ]}
        >
          <Select
            placeholder="Select domain"
            optionFilterProp="data-searchvalue"
            showSearch
            loading={loadingDomains}
            style={{ width: '100%' }}
          >
            {generateOptions(availableDomains, 'address', 'id')}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
