export const ChannelColumnGroups = {
  Dimensions: 'Dimensions',
  Metrics: 'Metrics',
  CampaignInfo: 'Campaign info',
  Pixels: 'Pixels',
  FiredPixels: 'Fired pixels',
  PixelsEarned: 'Pixels Earned',
  Product: 'Product',
  PartnerInfo: 'Partner info',
  QSParameters: 'QS parameters',
  EcommerceMetrics: 'E-commerce Metrics',
  Custom: 'Custom'
};

export const ChannelColumnType = {
  Dimension: 'dimension',
  Metric: 'metric',
  DerivedMetric: 'derived_metric',
  Custom: 'custom'
};

export const allChannelStatColumns = [
  { value: 'date', text: 'Date', group: ChannelColumnGroups.Dimensions, type: ChannelColumnType.Dimension },
  { value: 'hour', text: 'Hour', group: ChannelColumnGroups.Dimensions, type: ChannelColumnType.Dimension },
  {
    value: 'campaign_id',
    text: 'Campaign ID',
    group: ChannelColumnGroups.CampaignInfo,
    type: ChannelColumnType.Dimension
  },
  { value: 'campaign_title', text: 'Campaign Title', group: ChannelColumnGroups.CampaignInfo, type: ChannelColumnType.Dimension },
  { value: 'campaign_type', text: 'Campaign Type', group: ChannelColumnGroups.CampaignInfo, type: ChannelColumnType.Dimension },
  { value: 'campaign_status', text: 'Campaign Status', group: ChannelColumnGroups.CampaignInfo, type: ChannelColumnType.Dimension },
  { value: 'rule_type', text: 'Rule Type', group: ChannelColumnGroups.CampaignInfo, type: ChannelColumnType.Dimension },
  { value: 'rule_type_id', text: 'Rule Type ID', group: ChannelColumnGroups.CampaignInfo, type: ChannelColumnType.Dimension },
  { value: 'lp_title', text: 'LP Title', group: ChannelColumnGroups.CampaignInfo, type: ChannelColumnType.Dimension },
  { value: 'lp_id', text: 'LP ID', group: ChannelColumnGroups.CampaignInfo, type: ChannelColumnType.Dimension },
  { value: 'app_id', text: 'App ID', group: ChannelColumnGroups.CampaignInfo, type: ChannelColumnType.Dimension },
  { value: 'identity', text: 'Identity', group: ChannelColumnGroups.CampaignInfo, type: ChannelColumnType.Dimension },
  { value: 'os', text: 'OS', group: ChannelColumnGroups.Dimensions, type: ChannelColumnType.Dimension },
  { value: 'os_version', text: 'OS Version', group: ChannelColumnGroups.Dimensions, type: ChannelColumnType.Dimension },
  { value: 'browser', text: 'Browser', group: ChannelColumnGroups.Dimensions, type: ChannelColumnType.Dimension },
  {
    value: 'browser_version',
    text: 'Browser Version',
    group: ChannelColumnGroups.Dimensions,
    type: ChannelColumnType.Dimension
  },
  { value: 'country', text: 'Country', group: ChannelColumnGroups.Dimensions, type: ChannelColumnType.Dimension },
  { value: 'source', text: 'Source', group: ChannelColumnGroups.Dimensions, type: ChannelColumnType.Dimension },
  { value: 'channel', text: 'Channel', group: ChannelColumnGroups.Dimensions, type: ChannelColumnType.Dimension },
  {
    value: 'creator_first_name',
    text: 'Account manager',
    group: ChannelColumnGroups.Dimensions,
    type: ChannelColumnType.Dimension
  },
  { value: 'product_id', text: 'Product ID', group: ChannelColumnGroups.Product, type: ChannelColumnType.Dimension },
  { value: 'product_title', text: 'Product Title', group: ChannelColumnGroups.Product, type: ChannelColumnType.Dimension },
  {
    value: 'prod_company_name',
    text: 'Product Com. Name',
    group: ChannelColumnGroups.PartnerInfo,
    type: ChannelColumnType.Dimension
  },
  {
    value: 'adv_company_name',
    text: 'Traffic Company Name',
    group: ChannelColumnGroups.PartnerInfo,
    type: ChannelColumnType.Dimension
  },
  {
    value: 'im',
    text: 'Impressions',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'ri',
    text: 'Redirect impressions',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'dw',
    text: 'Downloads',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'pl',
    text: 'Leads',
    group: ChannelColumnGroups.Pixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'pf',
    text: 'Fired',
    group: ChannelColumnGroups.FiredPixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'p1',
    text: 'PX 1',
    group: ChannelColumnGroups.Pixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'p2',
    text: 'PX 2',
    group: ChannelColumnGroups.Pixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'p3',
    text: 'PX 3',
    group: ChannelColumnGroups.Pixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'p4',
    text: 'PX 4',
    group: ChannelColumnGroups.Pixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'p5',
    text: 'PX 5',
    group: ChannelColumnGroups.Pixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'p6',
    text: 'PX 6',
    group: ChannelColumnGroups.Pixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'p7',
    text: 'PX 7',
    group: ChannelColumnGroups.Pixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'p8',
    text: 'PX 8',
    group: ChannelColumnGroups.Pixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'p1_spam',
    text: 'PX 1 Spam',
    group: ChannelColumnGroups.Pixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'p1_not_spam',
    text: 'PX 1 Not Spam',
    group: ChannelColumnGroups.Pixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'spent',
    text: 'Spent',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'earned',
    text: 'Earned',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'profit',
    text: 'Profit',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: 'earned - spent',
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'roi',
    text: 'ROI',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: '100.0 * (earned / spent - 1)',
    output: 'percent',
    decimal_places: 2
  },
  {
    value: 'cpm',
    text: 'CPM',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: '1000.0 * (spent / im)',
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'rpm',
    text: 'RPM',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: '1000.0 * (earned / im)',
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'ctr',
    text: 'CTR',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: '100.0 * (dw / im)',
    output: 'percent',
    decimal_places: 2
  },
  {
    value: 'prpm',
    text: 'Partner RPM',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: '1000.0 * (spent / im)',
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'rpl',
    text: 'RPL',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: 'earned / pl',
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'partner_rpl',
    text: 'Partner RPL',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: 'spent / (pf + f1 + f2 + f3 + f4 + f5 + f6 + f7 + f8)',
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'ecpa',
    text: 'eCPA',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: 'spent / pl',
    output: 'currency',
    decimal_places: 3
  },
  {
    value: 'cr',
    text: 'CR',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: '100 * pl / im',
    output: 'percent',
    decimal_places: 2
  },
  {
    value: 'pcr',
    text: 'Partner CR',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: '100 * (pf + f1 + f2 + f3 + f4 + f5 + f6 + f7 + f8) / im',
    output: 'percent',
    decimal_places: 2
  },
  {
    value: 'pcrf8',
    text: 'Partner CR PX8',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: '100 * f8 / im',
    output: 'percent',
    decimal_places: 2
  },
  {
    value: 'f1',
    text: 'PF 1',
    group: ChannelColumnGroups.FiredPixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'f2',
    text: 'PF 2',
    group: ChannelColumnGroups.FiredPixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'f3',
    text: 'PF 3',
    group: ChannelColumnGroups.FiredPixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'f4',
    text: 'PF 4',
    group: ChannelColumnGroups.FiredPixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'f5',
    text: 'PF 5',
    group: ChannelColumnGroups.FiredPixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'f6',
    text: 'PF 6',
    group: ChannelColumnGroups.FiredPixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'f7',
    text: 'PF 7',
    group: ChannelColumnGroups.FiredPixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'f8',
    text: 'PF 8',
    group: ChannelColumnGroups.FiredPixels,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'real_fired',
    text: 'Real Fired',
    group: ChannelColumnGroups.FiredPixels,
    type: ChannelColumnType.DerivedMetric,
    formula: 'pf + f1 + f2 + f3 + f4 + f5 + f6 + f7 + f8',
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'pl__earned',
    text: 'Lead Earned',
    group: ChannelColumnGroups.PixelsEarned,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'p1__earned',
    text: 'PX 1 Earned',
    group: ChannelColumnGroups.PixelsEarned,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'p2__earned',
    text: 'PX 2 Earned',
    group: ChannelColumnGroups.PixelsEarned,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'p3__earned',
    text: 'PX 3 Earned',
    group: ChannelColumnGroups.PixelsEarned,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'p4__earned',
    text: 'PX 4 Earned',
    group: ChannelColumnGroups.PixelsEarned,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'p5__earned',
    text: 'PX 5 Earned',
    group: ChannelColumnGroups.PixelsEarned,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'p6__earned',
    text: 'PX 6 Earned',
    group: ChannelColumnGroups.PixelsEarned,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'p7__earned',
    text: 'PX 7 Earned',
    group: ChannelColumnGroups.PixelsEarned,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'p8__earned',
    text: 'PX 8 Earned',
    group: ChannelColumnGroups.PixelsEarned,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'wp__earned',
    text: 'WebPush Earned',
    group: ChannelColumnGroups.PixelsEarned,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'wp_loop__earned',
    text: 'WebPush Loop Earned',
    group: ChannelColumnGroups.PixelsEarned,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'search_rpl',
    text: 'Search RPL (p5_earned/p5)',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: 'p5__earned / p5',
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'rpl_px8',
    text: 'RPL PX8',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: 'earned / p8',
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'cr_px1',
    text: 'CR PX1',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: '100 * p1 / im',
    output: 'percent',
    decimal_places: 2
  },
  {
    value: 'cr_px2',
    text: 'CR PX2',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: '100 * p2 / im',
    output: 'percent',
    decimal_places: 2
  },
  {
    value: 'cr_px3',
    text: 'CR PX3',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: '100 * p3 / im',
    output: 'percent',
    decimal_places: 2
  },
  {
    value: 'cr_px4',
    text: 'CR PX4',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: '100 * p4 / im',
    output: 'percent',
    decimal_places: 2
  },
  {
    value: 'cr_px5',
    text: 'CR PX5',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: '100 * p5 / im',
    output: 'percent',
    decimal_places: 2
  },
  {
    value: 'cr_px6',
    text: 'CR PX6',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: '100 * p6 / im',
    output: 'percent',
    decimal_places: 2
  },
  {
    value: 'cr_px7',
    text: 'CR PX7',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: '100 * p7 / im',
    output: 'percent',
    decimal_places: 2
  },
  {
    value: 'cr_px8',
    text: 'CR PX8',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: '100 * p8 / im',
    output: 'percent',
    decimal_places: 2
  },
  {
    value: 'ecpa_px5',
    text: 'eCPA (px5)',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: 'spent / p5',
    output: 'currency',
    decimal_places: 3
  },
  {
    value: 'cr_px5_px6',
    text: 'CR (px5/px6)',
    group: ChannelColumnGroups.Metrics,
    type: ChannelColumnType.DerivedMetric,
    formula: '100 * p5 / p6',
    output: 'percent',
    decimal_places: 2
  },
  { value: 'pub_module', text: 'Publisher Module', group: ChannelColumnGroups.PartnerInfo, type: ChannelColumnType.Dimension },
  { value: 'source_1', text: 'QS 1', group: ChannelColumnGroups.QSParameters, type: ChannelColumnType.Dimension },
  { value: 'source_2', text: 'QS 2', group: ChannelColumnGroups.QSParameters, type: ChannelColumnType.Dimension },
  { value: 'source_3', text: 'QS 3', group: ChannelColumnGroups.QSParameters, type: ChannelColumnType.Dimension },
  { value: 'source_4', text: 'QS 4', group: ChannelColumnGroups.QSParameters, type: ChannelColumnType.Dimension },
  { value: 'source_5', text: 'QS 5', group: ChannelColumnGroups.QSParameters, type: ChannelColumnType.Dimension },
  { value: 'source_6', text: 'QS 6', group: ChannelColumnGroups.QSParameters, type: ChannelColumnType.Dimension },
  { value: 'source_7', text: 'QS 7', group: ChannelColumnGroups.QSParameters, type: ChannelColumnType.Dimension },
  { value: 'source_8', text: 'QS 8', group: ChannelColumnGroups.QSParameters, type: ChannelColumnType.Dimension },
  { value: 'source_9', text: 'QS 9', group: ChannelColumnGroups.QSParameters, type: ChannelColumnType.Dimension },
  {
    value: 'peh',
    text: 'PX Hold',
    group: ChannelColumnGroups.EcommerceMetrics,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'ped',
    text: 'PX Declined',
    group: ChannelColumnGroups.EcommerceMetrics,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'pec',
    text: 'PX Confirmed',
    group: ChannelColumnGroups.EcommerceMetrics,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'feh',
    text: 'PF Hold',
    group: ChannelColumnGroups.EcommerceMetrics,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'fed',
    text: 'PF Declined',
    group: ChannelColumnGroups.EcommerceMetrics,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'fec',
    text: 'PF Confirmed',
    group: ChannelColumnGroups.EcommerceMetrics,
    type: ChannelColumnType.Metric,
    output: 'decimal',
    decimal_places: 0
  },
  {
    value: 'peh__amount',
    text: 'PX Hold Amount',
    group: ChannelColumnGroups.EcommerceMetrics,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'ped__amount',
    text: 'PX Declined Amount',
    group: ChannelColumnGroups.EcommerceMetrics,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'pec__amount',
    text: 'PX Confirmed Amount',
    group: ChannelColumnGroups.EcommerceMetrics,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'feh__amount',
    text: 'PF Hold Amount',
    group: ChannelColumnGroups.EcommerceMetrics,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'fed__amount',
    text: 'PF Declined Amount',
    group: ChannelColumnGroups.EcommerceMetrics,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  {
    value: 'fec__amount',
    text: 'PF Confirmed Amount',
    group: ChannelColumnGroups.EcommerceMetrics,
    type: ChannelColumnType.Metric,
    output: 'currency',
    decimal_places: 2
  },
  { value: 'prod_tags', text: 'Product Com. Tags', group: ChannelColumnGroups.PartnerInfo, type: ChannelColumnType.Dimension },
  { value: 'adv_tags', text: 'Traffic Com. Tags', group: ChannelColumnGroups.PartnerInfo, type: ChannelColumnType.Dimension },
  {
    value: 'feed_id',
    text: 'Feed Id',
    group: ChannelColumnGroups.Dimensions,
    type: ChannelColumnType.Dimension
  }
];

export const hiddenColumns = ['creator_last_name', 'prod_brought_by_last_name', 'adv_brought_by_last_name'].map((c) => ({
  value: c,
  text: c
}));

export const getSelectedColumns = (columns) => {
  let selectedColumns = {};

  if (columns.length) {
    columns.forEach((column) => {
      const { selected, value } = column;
      if (selected) {
        selectedColumns[value] = true;
      }
    });
  }

  return selectedColumns;
};

const allChannelStatColumnsObj = allChannelStatColumns.reduce((acc, val) => {
  acc[val.value] = val;
  return acc;
}, {});

export const expandPresetColumns = (columns) => {
  const expandedColumns = columns.map((column, index) => {
    return { ...(allChannelStatColumnsObj[column.value] ?? { group: ChannelColumnGroups.Custom }), ...column };
  });

  return expandedColumns;
};

export const removeExtraFields = (columns) => {
  return columns.map((column) => {
    let returnVal = null;
    switch (column.type) {
      case ChannelColumnType.Dimension:
        returnVal = { value: column.value, selected: column.selected || undefined };
        break;
      case ChannelColumnType.Metric:
      case ChannelColumnType.DerivedMetric:
        returnVal = { value: column.value, selected: column.selected || undefined, newText: column.newText || undefined };
        break;
      case ChannelColumnType.Custom:
        returnVal = {
          value: column.value,
          selected: column.selected || undefined,
          newText: column.newText || undefined,
          type: column.type,
          formula: column.formula,
          output: column.output,
          decimal_places: column.decimal_places
        };

      //no default
    }
    return returnVal;
  });
};

export const allBasicMetrics = allChannelStatColumns
  .filter((c) => c.type === ChannelColumnType.Metric)
  .map((c) => ({ value: c.value, text: c.text }));
