import { callApi } from './api';
import { fieldSorter, handleOrdering } from '../utils/functions';

class FacebookPixelsService {
  async getAll() {
    return await callApi({ url: '/api/s2s_integrations/facebook_pixels/' });
  }

  async getPaged({ currentPage, pageSize, searchValue, cancelToken, sorting }) {
    const allPixels = await callApi({ url: '/api/s2s_integrations/facebook_pixels/', cancelToken });
    const searchValueLowercase = searchValue.toLowerCase();
    let filteredPixels = allPixels;
    if (searchValue) {
      filteredPixels = allPixels.filter((pixel) => {
        return pixel.id === searchValue || pixel.name.replace(' ', '').toLowerCase().includes(searchValueLowercase);
      });
    }
    const sortedPixels = filteredPixels.sort(fieldSorter(handleOrdering(sorting)));
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    return { results: sortedPixels.slice(start, end), count: allPixels.length };
  }

  async getSingle(id) {
    return await callApi({ url: `/api/s2s_integrations/facebook_pixels/${id}/` });
  }

  async add(data) {
    return await callApi({ url: '/api/s2s_integrations/facebook_pixels/', method: 'POST', data });
  }

  async edit(data, id) {
    return await callApi({ url: `/api/s2s_integrations/facebook_pixels/${id}/`, method: 'PUT', data });
  }

  async delete(id) {
    return await callApi({ url: `/api/s2s_integrations/facebook_pixels/${id}/`, method: 'DELETE' });
  }
}

export const facebookPixelsService = new FacebookPixelsService();
