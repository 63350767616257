import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, Form, Input, Button } from 'antd';
import { IdentityPurpose, identityService, IdentityType } from '../../../services/identity';
import { testsService } from '../../../services/tests';
import { Select } from '../../../components/Select';
import { confirmClose } from '../../../utils/confirmClose';
import { showApiErrors } from '../../../utils/showApiErrors';
import { openSuccessNotification } from '../../../utils/notifications';
import { accountsService } from '../../../services/accounts';
import { generateSelectOptionsWithIdValues } from '../../../utils/options';
import { getAccountsSelectOptions } from '../../lists/utils/options';

export const AddIdentityModal = ({ modalVisible, onCancel, onAdd }) => {
  const [form] = Form.useForm();
  const [wasFormChanged, setWasFormChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [testsLoading, setTestsLoading] = useState(false);
  const [allTests, setAllTests] = useState([]);

  const [allAccountsLoading, setAllAccountsLoading] = useState(false);
  const [allAccounts, setAllAccounts] = useState([]);

  useEffect(() => {
    form.resetFields();
    setWasFormChanged(false);
  }, [form, modalVisible]);

  useEffect(() => {
    const getTests = async () => {
      setTestsLoading(true);
      try {
        const tests = await testsService.getAll();
        setAllTests(tests);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setTestsLoading(false);
      }
    };

    const getAccounts = async () => {
      setAllAccountsLoading(true);
      try {
        const accounts = await accountsService.getAll();
        setAllAccounts(accounts);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAllAccountsLoading(false);
      }
    };

    getTests();
    getAccounts();
  }, []);

  const handleClose = () => {
    wasFormChanged ? confirmClose(onCancel) : onCancel();
  };

  const handleSubmit = async (values) => {
    try {
      setSubmitting(true);
      await identityService.create(values);
      openSuccessNotification({ message: 'Successfully added identity' });
      onAdd();
    } catch (e) {
      showApiErrors(e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title="Add Identity"
      visible={modalVisible}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={submitting} onClick={() => form.submit()}>
          Add
        </Button>
      ]}
      width={600}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        onFinishFailed={({ errorFields }) => {
          form.scrollToField(errorFields[0].name);
        }}
        onValuesChange={() => setWasFormChanged(true)}
      >
        <Row gutter={32}>
          <Col span={24}>
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input identity name!' }]}>
              <Input placeholder="Identity Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <Form.Item label="Purpose" name="purpose" rules={[{ required: true, message: 'Please select purpose!' }]}>
              <Select placeholder="Select purpose" showSearch style={{ width: '100%' }}>
                <Select.Option value={IdentityPurpose.WebPush}>WebPush</Select.Option>
                <Select.Option value={IdentityPurpose.Custom}>Custom</Select.Option>
                <Select.Option value={IdentityPurpose.AzureWebPush}>Azure WebPush</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <Form.Item label="Description" name="description">
              <Input placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <Form.Item label="Type" name="type" rules={[{ required: true, message: 'Please select type!' }]}>
              <Select placeholder="Select type" showSearch style={{ width: '100%' }}>
                <Select.Option value={IdentityType.Fixed}>Fixed</Select.Option>
                <Select.Option value={IdentityType.Replacement}>Replacement</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <Form.Item label="Tests" name="tests">
              <Select
                placeholder="Select tests"
                optionFilterProp="data-searchvalue"
                showSearch
                allowClear
                loading={testsLoading}
                style={{ width: '100%' }}
                mode="multiple"
              >
                {generateSelectOptionsWithIdValues(allTests)}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <Form.Item label="Notify Users" name="notify_users">
              <Select
                mode="multiple"
                placeholder="Select users"
                allowClear
                optionFilterProp="data-searchvalue"
                loading={allAccountsLoading}
                style={{ width: '100%' }}
              >
                {getAccountsSelectOptions(allAccounts)}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
