import { Button, Col, Form, Input, Modal, Row } from 'antd';

import React, { useMemo, useState, useEffect } from 'react';
import { facebookPixelsService } from '../../../services/facebook-pixels';
import { confirmClose } from '../../../utils/confirmClose';
import { openSuccessNotification } from '../../../utils/notifications';
import { showApiErrors } from '../../../utils/showApiErrors';
import { Spinner } from '../../../components/Spinner';

export const AddOrEditFacebookPixel = ({ modalVisible, facebookPixelId, onClose, onAdd, onEdit }) => {
  const [form] = Form.useForm();
  const [wasFormChanged, setWasFormChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [facebookPixel, setFacebookPixel] = useState();
  const [facebookPixelLoading, setFacebookPixelLoading] = useState(false);

  const action = facebookPixelId ? 'Edit' : 'Add';

  const initialValues = useMemo(() => {
    if (!facebookPixel) {
      return {};
    }

    return facebookPixel;
  }, [facebookPixel]);

  useEffect(() => {
    form.resetFields();
    setWasFormChanged(false);
  }, [form, initialValues, modalVisible]);

  useEffect(() => {
    const fetchPixel = async () => {
      if (facebookPixelId && modalVisible) {
        setFacebookPixelLoading(true);
        try {
          const pixel = await facebookPixelsService.getSingle(facebookPixelId);
          setFacebookPixel(pixel);
        } catch (e) {
          showApiErrors(e);
        } finally {
          setFacebookPixelLoading(false);
        }
      } else {
        setFacebookPixel(undefined);
      }
    };
    fetchPixel();
  }, [facebookPixelId, modalVisible]);

  const handleClose = () => {
    wasFormChanged ? confirmClose(onClose) : onClose();
  };

  const handleSubmit = async (values) => {
    try {
      setSubmitting(true);

      if (facebookPixelId) {
        await facebookPixelsService.edit(values, facebookPixelId);
        openSuccessNotification({ message: 'Successfully edited facebook pixel' });
        onEdit();
      } else {
        await facebookPixelsService.add(values);
        openSuccessNotification({ message: 'Successfully added facebook pixel' });
        onAdd();
      }
    } catch (e) {
      showApiErrors(e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title={`${action} Facebook Pixel`}
      visible={modalVisible}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={submitting} onClick={() => form.submit()}>
          {action}
        </Button>
      ]}
      width={600}
    >
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        layout="vertical"
        onFinishFailed={({ errorFields }) => {
          form.scrollToField(errorFields[0].name);
        }}
        onValuesChange={() => setWasFormChanged(true)}
      >
        {facebookPixelLoading ? (
          <Spinner />
        ) : (
          <>
            <Row gutter={32}>
              <Col span={24}>
                <Form.Item label="Pixel Id" name="id" rules={[{ required: true, message: 'Please input pixel id!' }]}>
                  <Input placeholder="Pixel Id" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24}>
                <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input pixel name!' }]}>
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24}>
                <Form.Item label="Access Token" name="token" rules={[{ required: true, message: 'Please input access token!' }]}>
                  <Input placeholder="Access Token" />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Modal>
  );
};
