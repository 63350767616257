import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { Col, Form, Input, Row, Tooltip, Spin as AntdSpinner, Checkbox, Switch } from 'antd';
import { CopyOutlined, DownSquareTwoTone, LoadingOutlined } from '@ant-design/icons';
import { usePromise } from 'react-use';

import { withPadlockIcon } from '../../utils/withPadlockIcon';
import { withCopyIcon } from '../../utils/withCopyIcon';
import { linkTimeToLiveOptions } from '../../utils/linkTimeToLiveOptions';
import { getSelectOptionsWithIdValues, getPrefixSelectOptions } from '../../utils/options';

import { Spinner } from '../../../../components/Spinner';
import { Select } from '../../../../components/Select';
import { VirtualizedSelect } from '../../../../components/VirtualizedSelect';

import { accountsService } from '../../../../services/accounts';
import { auth } from '../../../../services/auth';
import { MBTrafficCategory, companiesService } from '../../../../services/companies';
import { mediaCampaignsService } from '../../../../services/media-campaigns';

import { showApiErrors } from '../../../../utils/showApiErrors';

import styles from './Tabs.module.css';
import { openInfoNotification, openSuccessNotification } from '../../../../utils/notifications';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useEffectTrigger, useForm } from '../../../../utils/hooks';
import { LinkGeneratedMethod } from '../../utils/enums';
import { generateOptions } from '../../../../utils/options';
import { getAccounts, useCustomQuery } from '../../../../utils/hooks/queries';

const { Option } = Select;
const { TextArea } = Input;

const InputWithPadlockIcon = withPadlockIcon(Input);
const InputWithCopyIcon = withCopyIcon(Input);
const SelectWithPadlockIcon = withPadlockIcon(Select, 'suffixIcon');

export const Basic = ({
  form: antdForm,
  initialData,
  allIdentities,
  allIdentitiesLoading,
  loadingTabsData,
  isClone,
  onCampaignTypeChange,
  onCompanyChange,
  onFormChange,
  onRevShareSelected,
  isRevShare
}) => {
  const [thisUser, setThisUser] = useState(null);
  const [originalCreator, setOriginalCreator] = useState(null);
  const [creatorDisabled, setCreatorDisabled] = useState(false);
  const [loadingUserProfile, setLoadingUserProfile] = useState(false);

  const [campaignUrl, setCampaignUrl] = useState('');

  const [allCampaignTypes, setAllCampaignTypes] = useState([]);
  const [areCampaignTypesLoading, setAreCampaignTypesLoading] = useState(false);

  const [allStatuses, setAllStatuses] = useState([]);
  const [areStatusesLoading, setAreStatusesLoading] = useState(false);

  const [allLinkGenerateMethods, setAllLinkGenerateMethods] = useState([]);
  const [areLinkGenerateMethodsLoading, setAreLinkGenerateMethodsLoading] = useState(false);

  const [mbTrafficCompanies, setMbTrafficCompanies] = useState([]);
  const [mbTrafficCompaniesLoading, setMbTrafficCompaniesLoading] = useState(false);

  const [channelPrefixes, setChannelPrefixes] = useState([]);
  const [loadingCompanyDetails, setLoadingCompanyDetails] = useState(false);
  const [parsingChannel, setParsingChannel] = useState(false);
  const [disableURI, setDisableURI] = useState(true);
  const [channelCopyText, setChannelCopyText] = useState('');

  const [formChangedTrigger, activateFormChangedTrigger] = useEffectTrigger();
  const [form] = useForm(activateFormChangedTrigger, antdForm);

  const mounted = usePromise();

  const { isLoading: areAccountsLoading, isFetching: allAccountsRefetching, data: allAccounts } = useCustomQuery(getAccounts);

  const isEdit = initialData && !isClone;

  const handleCompanyChange = useCallback(
    // TODO: move to useEffect with formChangedTrigger
    async (id, channel) => {
      let newValues = {};
      setChannelPrefixes([]);

      if (!id || !channel) {
        newValues.prefix = undefined;
        newValues.cid = undefined;
        newValues.sid = undefined;
        newValues.additionalParams = undefined;
        form.setFieldsValue(newValues);
        onCompanyChange({ s2s_url: undefined });
      }

      if (!id) {
        return;
      }

      if (channel) {
        setParsingChannel(true);
      } else {
        setLoadingCompanyDetails(true);
      }

      let company = undefined;

      try {
        company = await companiesService.getCompany(id, { fields: 'prefixes,click_param,source_param,additional_params,s2s_url' });
      } catch (e) {
        if (e.response.status !== 404) {
          showApiErrors(e);
        }
      } finally {
        setParsingChannel(false);
        setLoadingCompanyDetails(false);
      }

      if (company) {
        const { prefixes, click_param, source_param, additional_params } = company;

        // Deep clone prefixes array
        const prefixesClone = JSON.parse(JSON.stringify(prefixes));
        // Reverse prefix order
        prefixesClone.reverse();

        if (channel) {
          let chosenPrefix = undefined;
          let channelName = channel;

          for (const prefix of prefixesClone) {
            if (channel.startsWith(prefix)) {
              chosenPrefix = prefix;
              channelName = channel.substring(prefix.length);
            }
          }

          newValues.prefix = chosenPrefix;
          newValues.channel = channelName;
        } else {
          newValues.cid = click_param;
          newValues.sid = source_param;
          newValues.additionalParams = additional_params;
          onCompanyChange(company);
        }

        setChannelPrefixes(prefixesClone);
      } else {
        newValues.channel = channel;
      }

      form.setFieldsValue(newValues);
    },
    [form, onCompanyChange]
  );

  const initialValues = useMemo(() => {
    if (!initialData) {
      return { creator: thisUser, linkTimeToLive: '1800', linkGenerateMethod: LinkGeneratedMethod.Standard, advancedTargeting: false };
    }

    const {
      partner_title,
      company,
      creator_details,
      type,
      tag,
      validate_domain,
      identity,
      content_uri,
      status,
      link_generate_method,
      link_ttl,
      click_param,
      source_param,
      camp_url,
      additional_params,
      partner_url,
      use_ua_client_hints,
      additional_data
    } = initialData;
    let { title, channel } = initialData;
    const { id: creatorId } = creator_details;

    title = isClone ? title + ' - clone' : title;
    channel = isClone ? channel + '_clone' : channel;
    const partnerTitle = partner_title;
    const creator = isClone ? thisUser : creatorId;
    const campaignType = type;
    const tags = tag;
    const uri = content_uri;
    const validateDomain = validate_domain;
    const linkGenerateMethod = link_generate_method;
    const linkTimeToLive = link_ttl ? link_ttl.toString() : undefined;
    const cid = click_param;
    const sid = source_param;
    const campaignUrl = (isEdit && camp_url) || '';
    const additionalParams = additional_params;
    const partnerUrl = partner_url;
    const advancedTargeting = use_ua_client_hints;
    const lpMacroConfig = additional_data ? JSON.stringify(additional_data) : undefined;

    setOriginalCreator(creatorId);
    setCampaignUrl(campaignUrl);
    setTimeout(() => {
      // setTimeout because onCompanyChange in parent component has setState
      onCampaignTypeChange(type);
      handleCompanyChange(company, channel);
    }, 0);

    return {
      title,
      partnerTitle,
      creator,
      company,
      campaignType,
      tags,
      validateDomain,
      identity,
      uri,
      status,
      linkGenerateMethod,
      linkTimeToLive,
      cid,
      sid,
      campaignUrl,
      additionalParams,
      partnerUrl,
      advancedTargeting,
      lpMacroConfig
    };
  }, [initialData, thisUser, isClone, onCampaignTypeChange, handleCompanyChange, isEdit]);

  useEffect(() => form.resetFields(), [form, initialValues]);

  useEffect(() => {
    const { prefix, channel, linkGenerateMethod } = form.getFieldsValue(true);

    setChannelCopyText(prefix ? prefix + channel : channel);

    if (linkGenerateMethod === LinkGeneratedMethod.Standard) {
      setDisableURI(true);
    } else {
      setDisableURI(false);
    }
  }, [form, formChangedTrigger]);

  useEffect(() => {
    if (disableURI) {
      form.setFieldsValue({ uri: '' });
    }
  }, [form, disableURI]);

  useEffect(() => {
    if (initialData && !isClone) {
      const isAdmin = auth.getPermissions() === true;

      setCreatorDisabled(thisUser !== originalCreator && !isAdmin);
    } else {
      setCreatorDisabled(true);
    }
  }, [initialData, isClone, thisUser, originalCreator]);

  useEffect(() => {
    const getThisUser = async () => {
      try {
        setLoadingUserProfile(true);
        const { id } = await mounted(auth.getUserProfile());
        setThisUser(id);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setLoadingUserProfile(false);
      }
    };

    const getLinkGenerateMethods = async () => {
      try {
        setAreLinkGenerateMethodsLoading(true);
        const linkGenerateMethod = await mounted(mediaCampaignsService.getLinkGenerateMethods());
        setAllLinkGenerateMethods(linkGenerateMethod);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAreLinkGenerateMethodsLoading(false);
      }
    };

    const getStatuses = async () => {
      try {
        setAreStatusesLoading(true);
        const statuses = await mounted(mediaCampaignsService.getCampaignStatuses());
        setAllStatuses(statuses);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAreStatusesLoading(false);
      }
    };

    const getCampaignTypes = async () => {
      try {
        setAreCampaignTypesLoading(true);
        const types = await mounted(mediaCampaignsService.getCampaignTypes());
        setAllCampaignTypes(types);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setAreCampaignTypesLoading(false);
      }
    };

    const getMbTrafficCompanies = async () => {
      try {
        setMbTrafficCompaniesLoading(true);
        const companies = await mounted(
          companiesService.getAll({
            fields: 'id,name',
            assigned_type: 'traffic',
            categories_names: [MBTrafficCategory.CpaRs.name, MBTrafficCategory.CpmCpc.name]
          })
        );
        setMbTrafficCompanies(companies);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setMbTrafficCompaniesLoading(false);
      }
    };

    getThisUser();
    getLinkGenerateMethods();
    getStatuses();
    getCampaignTypes();
    getMbTrafficCompanies();
  }, [mounted]);

  const ChannelLabel = () => {
    return (
      <>
        Channel &nbsp;
        <AntdSpinner spinning={parsingChannel}>
          <Tooltip title="Copy channel name">
            <CopyToClipboard
              text={channelCopyText}
              onCopy={() => {
                openSuccessNotification({ message: 'Successfully copied to clipboard!', duration: 8 });
              }}
            >
              <CopyOutlined />
            </CopyToClipboard>
          </Tooltip>
        </AntdSpinner>
      </>
    );
  };

  const advancedTargetingHelpTxt = (
    <div>
      <p>
        ON: When turned ON, Browser & OS version targeting implies additional redirect and with this feature you have accurate browser & OS
        versions stats.
      </p>
      <p>OFF: By turning Browser & OS version targeting off there won’t be accurate browser & OS versions stats.</p>
    </div>
  );
  return (
    <Form
      form={form}
      name="basicForm"
      initialValues={initialValues}
      onValuesChange={() => {
        onFormChange(true);
        activateFormChangedTrigger();
      }}
      layout="vertical"
    >
      {loadingTabsData ? (
        <Spinner />
      ) : (
        <Row gutter={32}>
          <Col span={8}>
            <Form.Item name="title" label="Title">
              <InputWithPadlockIcon placeholder="Title" isEdit={isEdit} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="partnerTitle" label="Partner Title">
              <InputWithPadlockIcon placeholder="Partner Title" isEdit={isEdit} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="company"
              label="Company"
              rules={[
                {
                  required: true,
                  message: 'Please select a company!'
                }
              ]}
            >
              <SelectWithPadlockIcon
                placeholder="Company"
                isEdit={isEdit}
                disabled={isEdit}
                loading={mbTrafficCompaniesLoading}
                onChange={(value) => handleCompanyChange(value, null)}
                showSearch
                optionFilterProp="data-searchvalue"
              >
                {generateOptions(mbTrafficCompanies, 'name', 'id')}
              </SelectWithPadlockIcon>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="creator" valuePropName="selectValue" label="Owner">
              <VirtualizedSelect
                placeholder="Owner"
                isEdit={isEdit}
                loading={areAccountsLoading || allAccountsRefetching || loadingUserProfile}
                disabled={creatorDisabled}
                options={accountsService.generateOptions(allAccounts)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Row className={styles.mergedCols}>
              <Col span={8}>
                <Form.Item
                  name="prefix"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please select a prefix!'
                  //   }
                  // ]}
                  label="Prefix"
                >
                  <SelectWithPadlockIcon
                    placeholder={loadingCompanyDetails ? <LoadingOutlined /> : 'Prefix'}
                    isEdit={isEdit}
                    showSearch
                    allowClear
                  >
                    {getPrefixSelectOptions(channelPrefixes)}
                  </SelectWithPadlockIcon>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  name="channel"
                  label={<ChannelLabel />}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a channel!'
                    }
                  ]}
                >
                  <InputWithPadlockIcon
                    placeholder={parsingChannel ? '' : 'Channel'}
                    prefix={parsingChannel && <LoadingOutlined />}
                    isEdit={isEdit}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={24} className={isRevShare ? styles.mergedCols : ''}>
              <Col span={24}>
                <Form.Item
                  name="campaignType"
                  label="Campaign Type"
                  rules={[
                    {
                      required: true,
                      message: 'Please select a type!'
                    }
                  ]}
                >
                  <SelectWithPadlockIcon
                    placeholder="Campaign Type"
                    showSearch
                    allowClear
                    isEdit={isEdit}
                    loading={areCampaignTypesLoading}
                    onChange={(value) => {
                      onCampaignTypeChange(value);
                      if (value === 'RS') {
                        onRevShareSelected(true);
                        openInfoNotification({ message: 'Set Revshare% in Pricing&S2S tab', duration: 8 });
                      } else if (isRevShare === true) {
                        onRevShareSelected(false);
                      }
                    }}
                  >
                    {getSelectOptionsWithIdValues(allCampaignTypes)}
                  </SelectWithPadlockIcon>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Form.Item name="tags" label="Tags">
              <InputWithPadlockIcon placeholder="Tags" isEdit={isEdit} />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item label="Validate Domain" name="validateDomain" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item
              name="identity"
              label="Identity"
              rules={[
                {
                  required: true,
                  message: 'Please select an identity!'
                }
              ]}
            >
              <SelectWithPadlockIcon
                placeholder="Identity"
                optionFilterProp="data-searchvalue"
                showSearch
                allowClear
                isEdit={isEdit}
                loading={allIdentitiesLoading}
              >
                {getSelectOptionsWithIdValues(allIdentities)}
              </SelectWithPadlockIcon>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="linkGenerateMethod"
              label="Link generate method"
              rules={[
                {
                  required: true,
                  message: 'Please select a link generate method!'
                }
              ]}
            >
              <SelectWithPadlockIcon
                placeholder="Link Generate Method"
                optionFilterProp="data-searchvalue"
                showSearch
                allowClear
                isEdit={isEdit}
                loading={areLinkGenerateMethodsLoading}
              >
                {getSelectOptionsWithIdValues(allLinkGenerateMethods)}
              </SelectWithPadlockIcon>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="uri" label="URI">
              <InputWithPadlockIcon placeholder="URI" disabled={disableURI} isEdit={isEdit} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="status"
              label="Status"
              rules={[
                {
                  required: true,
                  message: 'Please select a status!'
                }
              ]}
            >
              <SelectWithPadlockIcon
                placeholder="Status"
                optionFilterProp="data-searchvalue"
                showSearch
                allowClear
                isEdit={isEdit}
                loading={areStatusesLoading}
              >
                {getSelectOptionsWithIdValues(allStatuses)}
              </SelectWithPadlockIcon>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="linkTimeToLive" label="Link Time To Live">
              <Select>
                {linkTimeToLiveOptions.map((el) => {
                  return (
                    <Option key={el.value} value={el.value}>
                      {el.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="cid"
              label="Click Parameter"
              rules={[
                {
                  required: !isEdit,
                  message: 'Please enter a click parameter!'
                }
              ]}
            >
              <InputWithPadlockIcon
                placeholder={loadingCompanyDetails ? '' : 'cid'}
                prefix={loadingCompanyDetails && <LoadingOutlined />}
                isEdit={isEdit}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="sid"
              label="Source Parameter"
              rules={[
                {
                  required: !isEdit,
                  message: 'Please enter a source parameter!'
                }
              ]}
            >
              <InputWithPadlockIcon
                placeholder={loadingCompanyDetails ? '' : 'sid'}
                prefix={loadingCompanyDetails && <LoadingOutlined />}
                isEdit={isEdit}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="additionalParams"
              label="Additional Params"
              rules={[
                {
                  required: true,
                  message: 'Please enter additional parameters!'
                }
              ]}
            >
              <InputWithPadlockIcon
                placeholder={loadingCompanyDetails ? '' : 'cid=[CLICK_ID]&sid=[SUB_ID]'}
                prefix={loadingCompanyDetails && <LoadingOutlined />}
                isEdit={isEdit}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="advancedTargeting"
              label="Advanced Targeting - Browser & OS version targeting"
              valuePropName="checked"
              help={advancedTargetingHelpTxt}
            >
              <Switch className={styles.onOffSwitch} checkedChildren="ON" unCheckedChildren="OFF" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="campaignUrl" label="Campaign URL">
              <InputWithCopyIcon
                placeholder="Campaign URL"
                text={campaignUrl}
                additionalIcon={
                  isEdit ? (
                    <DownSquareTwoTone
                      style={{ marginRight: 5, cursor: 'pointer' }}
                      onClick={() => form.setFieldsValue({ partnerUrl: campaignUrl })}
                    />
                  ) : null
                }
                additionalIconTooltip="Paste to Partner URL"
              />
            </Form.Item>
          </Col>

          {isEdit && (
            <Col span={24}>
              <Form.Item name="partnerUrl" label="Partner URL">
                <InputWithCopyIcon placeholder="Partner URL" text={form.getFieldsValue().partnerUrl} />
              </Form.Item>
            </Col>
          )}

          <Col span={24}>
            <Form.Item
              name="lpMacroConfig"
              label="LP Macro Configuration"
              rules={[
                {
                  validator: async (_, value) => {
                    if (value) {
                      try {
                        JSON.parse(value);
                      } catch (e) {
                        throw new Error('Please type correct JSON data!');
                      }
                    }
                    return;
                  }
                }
              ]}
            >
              <TextArea placeholder="LP Macro Configuration" rows={4} />
            </Form.Item>
          </Col>
        </Row>
      )}
    </Form>
  );
};
