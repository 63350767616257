import React from 'react';
import { Table as AntTable, Pagination, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { TableTitle } from '../../../components/TableTitle';
import { IdentityPurpose } from '../../../services/identity';

export const Table = ({
  loading,
  refreshing,
  domainPurchases,
  totalItems,
  currentPage,
  pageSize,
  sorting,
  onPaginationChange,
  onSortingClick
}) => {
  const history = useHistory();

  const columns = [
    {
      title: (
        <TableTitle text="Buyer" value="creator" order={sorting.find((s) => s.key === 'creator')?.value} onSortingClick={onSortingClick} />
      ),
      dataIndex: 'creator'
    },
    {
      title: (
        <TableTitle
          text="Identity"
          value="identity_name"
          order={sorting.find((s) => s.key === 'identity_name')?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'identity_name',
      render: (identity) => {
        if (identity === null) {
          return 'Multiple Identities';
        }
        return identity;
      }
    },
    {
      title: (
        <TableTitle
          text="Purpose"
          value="purpose"
          order={sorting.find((s) => s.key === 'purpose')?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'purpose',
      render: (purpose) => {
        if (purpose === IdentityPurpose.AzureWebPush) {
          return 'Azure WebPush';
        }
        return purpose;
      }
    },
    {
      title: <TableTitle text="Type" value="type" order={sorting.find((s) => s.key === 'type')?.value} onSortingClick={onSortingClick} />,
      dataIndex: 'type'
    },
    {
      title: (
        <TableTitle text="Status" value="status" order={sorting.find((s) => s.key === 'status')?.value} onSortingClick={onSortingClick} />
      ),
      dataIndex: 'status',
      align: 'center',
      render: (status) => (
        <Spin spinning={refreshing && !(status === 'DONE' || status === 'FAILED')} size="small">
          {status}
        </Spin>
      )
    },
    {
      title: (
        <TableTitle
          text="Date Purchased"
          value="date_created"
          order={sorting.find((s) => s.key === 'date_created')?.value}
          onSortingClick={onSortingClick}
        />
      ),
      dataIndex: 'date_created',
      align: 'center',
      render: (date_created) => dayjs(date_created).format('DD.MM.YYYY. - HH:mm:ss')
    }
  ];

  return (
    <div>
      <AntTable
        loading={loading}
        dataSource={domainPurchases}
        style={{ marginTop: '3%' }}
        rowKey={({ request_id }) => request_id}
        columns={columns}
        pagination={false}
        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
        onRow={({ request_id }) => {
          return {
            onClick: () => {
              history.push(`/domains/domain-purchase/${request_id}`);
            }
          };
        }}
        footer={() => {
          // default pagination gets hidden if no data on current page so we must use custom (with default it's possible to get stuck on some page if for example you delete the last item on last page if it's the only item there)
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={onPaginationChange}
              showSizeChanger={false}
              pageSize={pageSize}
            />
          );
        }}
      />
    </div>
  );
};
