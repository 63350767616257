import React, { useEffect, useState } from 'react';

import { Table } from './components/Table';
import { identityService } from '../../services/identity';
import { useFetch } from '../../utils/hooks';

export const DomainAutoRenew = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);

  const loadRenewalReadyDomains = (cancelToken) => identityService.getRenewalReadyDomains({ currentPage, pageSize, cancelToken });

  const [
    {
      data: { results: domains, count: totalItems },
      isFetching
    },
    getRenewalReadyDomains
  ] = useFetch(loadRenewalReadyDomains);

  useEffect(() => {
    getRenewalReadyDomains();
  }, [currentPage, getRenewalReadyDomains]);

  return (
    <div>
      <h1>Domain Auto-Renew</h1>
      <Table
        loading={isFetching}
        domains={domains}
        totalItems={totalItems}
        currentPage={currentPage}
        pageSize={pageSize}
        onPaginationChange={(page) => setCurrentPage(page)}
        onCancelAutoRenew={() => getRenewalReadyDomains()}
      />
    </div>
  );
};
